import { WORLD_WIDE } from './../../plans.constants';
import { identity } from 'rxjs';
import { pickBy } from 'lodash';
import { PlanModel } from './../../store/plans.models';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { COUNTRIES, PLAN_OPTIONS } from '../../plans.constants';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-plan-form',
  templateUrl: './plan-form.component.html',
  styleUrls: ['./plan-form.component.scss']
})
export class PlanFormComponent implements OnInit, OnChanges {
  @Input()
  isEdit = false;
  @Input()
  visible: boolean;
  @Input()
  modalId;
  @Input()
  plan: PlanModel;
  @Output()
  formSubmit = new EventEmitter();
  @Output()
  deleteEntity = new EventEmitter();
  @Output()
  visibleChange = new EventEmitter();
  planForm: FormGroup;
  worldWide = false;
  step = 1;
  @Input()
  currencies;



  coverage = COUNTRIES;
  options = PLAN_OPTIONS;

  constructor(private fb: FormBuilder, public translate: TranslateService) { }

  ngOnInit() {
    console.log(this.plan);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.plan && changes.plan.currentValue !== changes.plan.previousValue) {
      this.initializeForm();
    }
  }

  initializeForm() {
    const controlsPlanOptions = this.options.map(c => new FormControl(false));

    const controlsCoverage = this.coverage.map(c => new FormControl(false));    

    if (this.plan.id) {
      for (const index in this.options) {

        const data = this.options[index];

        this.plan.options.forEach(element => {
          if (element.value == data.value) {
            controlsPlanOptions[index].setValue(true);
          }
        });

      }

      const coverageString = this.plan.coverage.split(',');

      if (coverageString.indexOf(WORLD_WIDE) > -1 || coverageString.indexOf('Worldwide') > -1) {
        this.worldWide = true;

        for (const index in this.coverage) {
          controlsCoverage[index].reset({ value: this.worldWide, disabled: this.worldWide });
        }
      } else {
        for (const index in this.coverage) {
          const data = this.coverage[index];
          if (coverageString.indexOf(data.name) > -1) {
            controlsCoverage[index].setValue(true);
          }
        }
      }
    }

    this.planForm = this.fb.group({
      title: [this.plan.title || '', Validators.required],
      description: [this.plan.description || ''],
      options: new FormArray(controlsPlanOptions, Validators.required),
      coverage: new FormArray(controlsCoverage, Validators.required),
      currencyId: [this.plan.currencyId, Validators.required],
      cfe: [this.plan.cfe || '']
    });
  }

  handleSubmit() {
    if (this.planForm.valid) {
      const planData = pickBy(
        {
          ...this.planForm.value,
          id: this.plan ? this.plan.id : '',
          companyId: this.plan.companyId ? this.plan.companyId : 0
        },
        identity
      );

      const selectedOptionsValues = this.planForm.value.options
        .map((v, i) => (v ? this.options[i].label : null))
        .filter(v => v !== null);

      planData.options = selectedOptionsValues.join(',');

      if (this.worldWide) {
        planData.coverage = WORLD_WIDE;
      } else {
        const selectedCoverageValues = this.planForm.value.coverage
          .map((v, i) => (v ? this.coverage[i].name : null))
          .filter(v => v !== null);

        planData.coverage = selectedCoverageValues.join(',');
      }

      this.formSubmit.emit(planData);

      this.closeForm();
    }
  }

  handleDelete() {
    this.visible = false;
    this.visibleChange.emit(false);
    this.deleteEntity.emit(this.plan.id);
  }

  handleNextButtonClick(page) {
    console.log(page)
    this.step = this.step + page;
  }

  closeForm() {
    this.visible = false;
    this.step = 1;
    this.visibleChange.emit(false);
  }

  handleWorldWideCheckClick() {
    this.worldWide = !this.worldWide;

    for (const c of (<any>this.planForm.controls.coverage).controls) {
      // c.setValue(this.worldWide);
      c.reset({ value: this.worldWide, disabled: this.worldWide });
    }
  }
  get titleText() {
    return this.isEdit ? this.translate.instant('PLANS.EDIT_PLANS') : this.translate.instant('PLANS.add_plan');
  }
}
