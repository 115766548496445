export const COLUMNS = [
  // {
  //   label: 'Company Id',
  //   labelFr: 'ID Entreprise',
  //   dataKey: 'id'
  // },
  {
    label: 'Company Name',
    labelFr: 'Nom',
    dataKey: 'name'
  },
  {
    label: 'Company description',
    labelFr: 'Description',
    dataKey: 'description'
  }
];

export const ACTION_COLUMN = {
  label: 'Edit companies',
  labelFr: 'Modifier entreprise',
  // buttonLabel: 'Edit',
  // buttonLabelFr: 'Modifier'
  buttonIcon: 'fa fa-edit',
};
