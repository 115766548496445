import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Input
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements AfterViewInit {

  @Output()
  termChange = new EventEmitter();
  @Input()
  showSearchBtn = true;
  @Input()
  subNavBarXl = false;

  constructor(public translate: TranslateService) { }
  @ViewChild('input')
  input: ElementRef;

  ngAfterViewInit() {
    if (this.input) {
      const term = fromEvent(this.input.nativeElement, 'keyup').pipe(
        map((i: any) => i.currentTarget.value)
      );
      const debouncedTerm = term.pipe(debounceTime(600));
      debouncedTerm.subscribe(value => this.termChange.emit(value));
    }

  }
}
