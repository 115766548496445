import { Action } from '@ngrx/store';
import { UserModel, RoleModel } from './users.models';

export const REQUEST_USERS = 'REQUEST_USERS',
  REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS',
  REQUEST_USERS_FAIL = 'REQUEST_USERS_FAIL',
  CREATE_USER = 'CREATE_USER',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  REQUEST_ROLES = 'REQUEST_ROLES',
  REQUEST_ROLES_SUCCESS = 'REQUEST_ROLES_SUCCESS',
  DELETE_USER = 'DELETE_USER',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export class RequestUsers implements Action {
  readonly type = REQUEST_USERS;
  constructor() { }
}
export class RequestUsersSuccess implements Action {
  readonly type = REQUEST_USERS_SUCCESS;
  constructor(public payload: UserModel[]) { }
}

export class CreateUser implements Action {
  readonly type = CREATE_USER;
  constructor(public payload: { body: UserModel }) { }
}

export class CreateUserSuccess implements Action {
  readonly type = CREATE_USER_SUCCESS;
  constructor(public payload: UserModel[]) { }
}

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;
  constructor(public payload: UserModel) { }
}

export class UpdateUserSuccess implements Action {
  readonly type = UPDATE_USER_SUCCESS;
  constructor(public payload: UserModel) { }
}

export class RequestRoles implements Action {
  readonly type = REQUEST_ROLES;
  constructor() { }
}

export class RequestRolesSuccess implements Action {
  readonly type = REQUEST_ROLES_SUCCESS;
  constructor(public payload: RoleModel[]) { }
}

export class DeleteUser implements Action {
  readonly type = DELETE_USER;
  constructor(public payload: UserModel) { }
}

export class DeleteUserSuccess implements Action {
  readonly type = DELETE_USER_SUCCESS;
  constructor(public payload: UserModel) { }
}

export type Actions =
  | RequestUsers
  | RequestUsersSuccess
  | CreateUser
  | CreateUserSuccess
  | UpdateUser
  | UpdateUserSuccess
  | RequestRoles
  | RequestRolesSuccess
  | DeleteUser
  | DeleteUserSuccess;
