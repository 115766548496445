import { ApiService } from './../../../services/api.service';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

const ACCEPT_ATRIBUTE_MAP = {
  csv: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  pdf: 'application/pdf'
};

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent {
  @Input() label: string;
  @Input() uploadText: string;
  @Input() container: string;
  @Input() fileName: string;
  @Input() watchText: string;
  @Input() smallButton: boolean;
  @Input() params: any;
  @Input() disabled: boolean;
  @Input() cssClass: string;
  @Input() iconClass: string;
  @Output() uploadSuccess = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  _accept: string;
  loading = false;
  error: any
  constructor(private api: ApiService) { }

  @Input()
  set accept(key: string) {
    this._accept = ACCEPT_ATRIBUTE_MAP[key] || '*';
  }

  get accept(): string {
    return this._accept;
  }

  upload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const uploadParams = {
        uploadType: this.container,
        file,
        planId: this.params && this.params.planId,
        companyId: this.params && this.params.companyId,
        cfe: this.params && this.params.cfe,
        name: this.fileName,
        locale: this.params && this.params.locale
      };
      this.loading = true;
      this.api.upload(uploadParams).subscribe((response) => {
        this.loading = false;
        this.handleUploadSuccess(response)
      }, (err) => {
        this.loading = false;
        this.fileName = ''
        console.error(err)
        this.error = err
      });
    }
  }

  download() {
    const url = this.api.downloadUrl({ container: this.container, fileName: this.fileName });
    window.open(url);
  }

  clear() {
    this.fileName = '';
    this.fileInput.nativeElement.value = '';
  }

  handleUploadSuccess(response) {
    // const fileResult = response.result.files.file[0];
    // const fileName = (fileResult && fileResult.name) || '';

    const filePath = response.absolutePath

    this.uploadSuccess.emit(filePath);
  }
}
