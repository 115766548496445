import { AuthGuardService } from './../services/auth-guard.service';
import { Observable } from 'rxjs';
import { AppState } from './../app.state';
import { Router } from '@angular/router';
import { ApiService } from './../services/api.service';
import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DashboardGuard } from '../services/dashboard.guard';

declare var jQuery: any;
declare var $: any;

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {

	@Input() clientConfigData:any;

	toastVisibility: Observable<boolean>;
	toastMessage: Observable<string>;
	toggle = false;
	translateService: any;
	currentLang:any;

	constructor(private store: Store<AppState>, private apiService: ApiService, private router: Router,
		public translate: TranslateService
	) {
		translate.addLangs(['EN', 'FR']);
		translate.setDefaultLang('EN');

		const browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/EN|FR/) ? browserLang : 'EN');

		this.translateService = translate;
		this.currentLang = translate.getDefaultLang()

		this.toastVisibility = store.pipe(select('app', 'toast', 'show'));
		this.toastMessage = store.pipe(select('app', 'toast', 'message'));
	}

	ngOnInit() {

	}

	changeLang(lang){
		this.translate.use(lang)
		this.currentLang = lang
	}

	get isLoginRoute(): boolean {

		return this.router.isActive('/login', false);
	}

	get isCompanyRoute(): boolean {

		return this.router.isActive('/company', false);
	}

	get isDocumentationRoute(): boolean {

		return this.router.isActive('/company-documentation', false);
	}

	get showLogout(): boolean {
		return !this.isDocumentationRoute
	}

	get showNavBar(): boolean {
		return !(this.isLoginRoute || this.isCompanyRoute || this.isDocumentationRoute)
	}

	get role() {
		return localStorage.getItem('userRole');
	}

	goDashboard() {
		DashboardGuard.chooseApp.next(true);
		localStorage.setItem('chooseApp', '1');
		this.router.navigateByUrl('/dashboard')
	}
	handleLogout() {
		this.apiService.logout();
	}

	// toggle() {
	// 	$('#offcanvas').offcanvas("toggle");
	// }

	get isIndexed(): boolean {

		let body = document.getElementsByTagName('body')[0];
		if (DashboardGuard.chooseApp.value) {
			body.classList.add("bg");   //add the class
		} else {
			body.classList.remove("bg");   //remove the class
		}
		return DashboardGuard.chooseApp.value;
	}

	onActivate(event) {
		window.scroll(0,0);
		//or document.body.scrollTop = 0;
		//or document.querySelector('body').scrollTo(0,0)
		
	}

}
