import { createSelector } from '@ngrx/store';
import { keyBy, map } from 'lodash';
import * as UsersActions from './users.actions';
import { AppState } from './../../app.state';
import { STATUSES } from '../users.constants';
import { findIndex } from 'lodash';
import { UsersStateModel } from './users.models';

/* State */
const initialState: UsersStateModel = {
  list: [],
  roles: []
};

/* Reducers */
export function usersReducer(state = initialState, action) {
  switch (action.type) {
    case UsersActions.REQUEST_USERS_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    case UsersActions.CREATE_USER_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload]
      };
    case UsersActions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        list: state.list.map(user => (user.id === action.payload.id ? action.payload : user))
      };
    case UsersActions.DELETE_USER_SUCCESS:
      return {
        ...state,
        list: state.list.filter(user => user.id !== action.payload)
      };
    case UsersActions.REQUEST_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload
      };
    default:
      return state;
  }
}

/* Selectors */
export const selectUsers = (state: AppState) => {
  return state.users.list.map(user => ({
    ...user,
    status: user.enabled ? STATUSES.active : STATUSES.inactive
  }));
};

export const selectRoles = (state: AppState) => {
  return state.users.roles;
};
