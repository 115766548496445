import { AocInsurancePlanBreakdownComponent } from './../aoc-insurance-plan-breakdown/aoc-insurance-plan-breakdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipe } from './pipes/filter.pipe';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightModalComponent } from './components/right-modal/right-modal.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TableComponent } from './components/table/table.component';
import { ToastComponent } from './components/toast/toast.component';
import { SearchPipe } from './pipes/search.pipe';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentPipePipe } from './pipes/moment-pipe.pipe';
import { ModalComponent } from './components/modal/modal.component';

@NgModule({
  providers: [
    SearchPipe,
    FilterPipe,
    MomentPipePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    RightModalComponent,
    SearchBarComponent,
    NavbarComponent,
    TableComponent,
    ToastComponent,
    SearchPipe,
    FilterPipe,
    MomentPipePipe,
    UploadButtonComponent,
    SelectCompanyComponent,
    AocInsurancePlanBreakdownComponent,
    ModalComponent
  ],
  exports:  [
    RightModalComponent,
    SearchBarComponent,
    NavbarComponent,
    TableComponent,
    ToastComponent,
    SearchPipe,
    FilterPipe,
    MomentPipePipe,
    UploadButtonComponent,
    SelectCompanyComponent,
    TranslateModule,
    AocInsurancePlanBreakdownComponent,
    ModalComponent
  ]
})
export class SharedModule { }
