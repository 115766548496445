import * as AppActions from './app.actions';

const initialState = {
  toast: {
    show: false,
    message: ''
  },
  apiError: ''
};

export function appReducer(state = initialState, action: AppActions.Actions) {
  switch (action.type) {
    case AppActions.TOGGLE_TOAST:
      return {
        ...state,
        toast: action.payload
      };
    case AppActions.RAISE_API_ERROR:
      return {
        ...state,
        apiError: action.payload
      };
    default:
      return state;
  }
}
