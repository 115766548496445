import { PLAN_OPTIONS } from './../plans/plans.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, FILE_CONTAINERS } from './../services/api.service';
import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css'],
})
export class CompareComponent {

  aLanguaje = [
    'EN',
    'FR'
  ]
  aCurrency;
  language = 'EN';
  currency = 1;
  companies: any;
  cfe = false;
  insurancePlans: any;
  filteredInsurancePlans: any;
  selectedPlans = [];
  prospectId: any;
  plans = PLAN_OPTIONS;

  logoContainer = FILE_CONTAINERS.logos;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {

  }


  ngOnInit() {

    this.prospectId = this.route.snapshot.params['prospectId'];

    let params = {
      "filter": {
        "include": ["company", "currency", { "planGuarantees": "guarantee" }],
        "where": { "options": { "neq": "" }, "company": { "neq": null } }
      }
    }

    this.api.get('/InsurancePlans', params).subscribe((aInsurancePlans: any) => {
      let data;

      data = aInsurancePlans.map((e) => {

        e.options = e.options.split(',').map(it => it.toUpperCase())

        e.options = this.plans.filter(plan => e.options.includes(plan.label.toUpperCase()));

        return e;
      })
      data = aInsurancePlans.map((e) => {
        if (e.company) {
          const fileName = e.company.logo
          e.company.logo = this.api.downloadUrl({ container: this.logoContainer, fileName });
        }
        return e;
      })

      this.insurancePlans = data
      this.filteredInsurancePlans = [].concat(data)


    }, (err) => {
      console.error(err)
    })

    this.api.get('/Currencies').subscribe((currencies: any) => {
      this.aCurrency = currencies
      console.log('aCurrency:', this.aCurrency);
      console.log("currency:", this.currency)
      if (this.aCurrency[0].id != this.currency) {
        this.currency = this.aCurrency[0].id;
        console.log("currency set automatically to " + this.currency + " (" + this.aCurrency[0].name + ")");
      }
    }, (err) => {
      console.error(err)
    })

  }

  filter($event) {
    this.filteredInsurancePlans = []

    if (this.insurancePlans && this.insurancePlans.length) {
      this.filteredInsurancePlans = this.insurancePlans.filter(item => {
        if ((item.currencyId == this.currency)
          && (item.cfe == this.cfe)
        ) {
          return true;
        }

      });
      console.log("new currency:", this.currency)
    }
  }

  addItem(val) {
    let index = this.selectedPlans.indexOf(val);
    if (index === -1) {
      // val not found, pushing onto array
      this.selectedPlans.push(val);
    } else {
      // val is found, removing from array
      this.selectedPlans.splice(index, 1);
    }
  }

  compare() {

    let plans = this.selectedPlans.map(function (item) {
      return {
        "planId": item.id,
        "company": item.company.name,
        "companyId": item.company.id,
        "logo": item.company.logo,
        "enTitle": item.title,
        "frTitle": item.title,
        "frFranchise": "",
        "enFranchise": "",
        "frPremiumRate": "",
        "enPremiumRate": ""
      };
    });


    let companies = this.getCompanies(plans);

    let guarantees = {};

    for (let entry of this.selectedPlans) {

      for (let pg of entry.planGuarantees) {

        if (!pg.guaranteeId) {
          continue
        }

        if (!guarantees[pg.guaranteeId]) {
          guarantees[pg.guaranteeId] = {
            "guaranteeId": pg.guaranteeId,
            "enTitle": pg.guarantee.enTitle,
            "frTitle": pg.guarantee.frTitle,
            "colour": pg.guarantee.colour,
            "values": {}
          }
        }

        guarantees[pg.guaranteeId].values[pg.insurancePlanId] = {
          "frDetail": pg.frDetail,
          "enDetail": pg.enDetail
        }
      }
    }


    let toSend = {
      'plans': plans,
      'companies': companies,
      'guarantees': Object.values(guarantees)
    }

    this.api.post('/Comparisons',
      {
        'data': toSend,
        'corporateProspectId': this.prospectId,
        'currencyId': this.currency,
        'lang': this.language.toLocaleLowerCase(),
        'cfe': this.cfe ? true : false
      }
    ).subscribe(
      (comparison: any) => {
        console.log(comparison)
        this.router.navigateByUrl('/breakdown/' + comparison.id);
      }
      , (err) => {
        console.error(err)
      })


  }

  getCompanies(plans) {

    let companies = []
    let companyId = 0

    for (let plan of plans) {

      if (!this.checkExistCompany(companies, plan.companyId)) {
        companyId = plan.companyId
        companies.push({
          name: plan.company,
          id: plan.companyId,
          files: [{}]
        })
      }
    }

    return companies
  }

  private checkExistCompany(companies, companyId) {

    for (let company of companies) {
      if (companyId == company.id) {
        return true
      }
    }

    return false
  }

}
