import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'aoc-insurance-plan-breakdown',
  templateUrl: './aoc-insurance-plan-breakdown.component.html',
  styleUrls: ['./aoc-insurance-plan-breakdown.component.scss']
})
export class AocInsurancePlanBreakdownComponent implements OnInit {

  @Input()
  comparison: any;

  showingElements: any;
  Arr = Array;
  showingPlans: any;
  currentPage: 1;

  constructor() { }

  ngOnInit() {
  }

  ngDoCheck() {
    if (this.comparison) {
      this.showingElements = this.Paginator(this.comparison.data.plans, this.currentPage, 4)
    }
  }

  Paginator(items, page?, per_page?) {

    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,

      paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(items.length / per_page);
    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: (total_pages > page) ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems
    };
  }

  navPage(page) {
    this.currentPage = page
    this.showingElements = this.Paginator(this.comparison.data.plans, page, 4)
  }


  mark(guaranteeId) {
    console.log('guaranteeId', guaranteeId)
    let data = this.comparison.data.guarantees.filter(function (e) {
      return guaranteeId == e.guaranteeId
    })
    console.log('data[0].color', data[0].color)
    if (!data[0].color) {
      data[0].color = 'dark-grey'
    } else {
      data[0].color = null
    }

  }

  updateGuarantee($event, guaranteeId, planValue) {

    let data = this.comparison.data.guarantees.filter(function (e) {
      return guaranteeId == e.guaranteeId
    })

    if (this.comparison.lang == 'en') {
      data[0].values[planValue].enDetail = $event.target.value
    } else if (this.comparison.lang == 'fr') {
      data[0].values[planValue].frDetail = $event.target.value
    }

  }

  updatePlan($event, planValue, field) {

    let data = this.comparison.data.plans.filter(function (e) {
      return planValue.planId == e.planId
    })

    data[0][field] = $event.target.value
  }

}
