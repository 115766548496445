import { ApiService } from './../services/api.service';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from './../app.state';
import { UserModel, RoleModel } from './store/users.models';
import { REQUEST_USERS, CREATE_USER, UPDATE_USER, REQUEST_ROLES, DELETE_USER } from './store/users.actions';
import { COLUMNS, ACTION_COLUMN } from './users.constants';
import { selectUsers, selectRoles } from './store/users.reducer';

export interface UserItemModel {
  id: Number;
  enabled: Boolean;
  email: String;
  username?: String;
  emailVerified?: Boolean;
  realm?: String;
  personId: number;
  type: string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public usersList: Observable<UserModel[]>;
  public rolesList: Observable<RoleModel[]>;
  public columns = COLUMNS;
  public actionColumn = ACTION_COLUMN;
  public isUserFormVisible = false;
  public isEditUserForm = false;
  public editUserData: UserItemModel = <UserItemModel>{};
  public errorMsg: Observable<any>;
  public searchTerm: string;
  public modalId = 'user-form';

  constructor(private store: Store<AppState>) {
    this.usersList = this.store.pipe(select(selectUsers));
    this.rolesList = this.store.pipe(select(selectRoles));
  }

  ngOnInit() {
    
    let params =
    {
      "filter": {
        "where": {
          "type": {
            'neq': 'ADMIN SUPER'
          }
        }
      }
    }

    let paramsRoles = {
      "filter": {
        "where": {
          "name": {
            'neq': 'ADMIN SUPER'
          }
        }
      }
    }

    this.store.dispatch({ type: REQUEST_USERS, payload: params });
    this.store.dispatch({ type: REQUEST_ROLES, payload: paramsRoles });
  }
  handleSearchTermChange(term) {
    this.searchTerm = term;
  }
  handleFormSubmit(payload) {
    const actionType = this.isEditUserForm ? UPDATE_USER : CREATE_USER;
    this.store.dispatch({ type: actionType, payload });
  }

  handleDelete(id) {
    this.store.dispatch({ type: DELETE_USER, payload: id });
  }

  handleAddButtonClick() {
    this.isEditUserForm = false;
    this.isUserFormVisible = true;
    this.editUserData = <UserItemModel>{};
  }
  handleEditButtonClick(user) {
    if (user) {
      this.isEditUserForm = true;
      this.isUserFormVisible = true;
      this.editUserData = user;
    }
  }
}
