import { IColumn } from '../shared/components//table/table.model';

export const COLUMNS: Array<IColumn> = [
  // {
  //   label: 'Plan Id',
  //   labelFr: 'ID',
  //   dataKey: 'id'
  // },
  {
    label: 'Plan Title',
    labelFr: 'Titre plan',
    dataKey: 'title'
  },
  {
    label: 'Plan Options',
    labelFr: 'Options',
    dataKey: 'options',
    isArray: true
  },
  {
    label: 'Currency',
    labelFr: 'Devise',
    dataKey: 'currency.name'
  },
  {
    label: 'CFE',
    labelFr: 'CFE',
    dataKey: 'cfe'
  }
];

export const ACTION_COLUMN = {
  label: 'Edit Plans',
  labelFr: 'Modifier',
  // buttonLabel: 'Edit',
  // buttonLabelFr: 'Modifier',
  buttonIcon: 'fa fa-edit',
};

export const COUNTRIES = [
  { name: 'Afghanistan', fr: 'Afghanistan', isChecked: false },
  { name: 'Albania', fr: 'Albanie', isChecked: false },
  { name: 'Algeria', fr: 'Algérie', isChecked: false },
  { name: 'Andorra', fr: 'Andorre', isChecked: false },
  { name: 'Angola', fr: 'Angola', isChecked: false },
  { name: 'Anguilla', fr: 'Anguilla', isChecked: false },
  { name: 'Antigua & Barbuda', fr: 'Antigua-et-Barbuda', isChecked: false },
  { name: 'Argentina', fr: 'Argentine', isChecked: false },
  { name: 'Armenia', fr: 'Arménie', isChecked: false },
  { name: 'Australia', fr: 'Australie', isChecked: false },
  { name: 'Austria', fr: 'Autriche', isChecked: false },
  { name: 'Azerbaijan', fr: 'Azerbaïdjan', isChecked: false },
  { name: 'Bahamas', fr: 'Bahamas', isChecked: false },
  { name: 'Bahrain', fr: 'Bahreïn', isChecked: false },
  { name: 'Bangladesh', fr: 'Bangladesh', isChecked: false },
  { name: 'Barbados', fr: 'Barbade', isChecked: false },
  { name: 'Belarus', fr: 'Biélorussie', isChecked: false },
  { name: 'Belgium', fr: 'Belgique', isChecked: false },
  { name: 'Belize', fr: 'Belize', isChecked: false },
  { name: 'Benin', fr: 'Bénin', isChecked: false },
  { name: 'Bermuda', fr: 'Bermudes', isChecked: false },
  { name: 'Bhutan', fr: 'Bhoutan', isChecked: false },
  { name: 'Bolivia', fr: 'Bolivie', isChecked: false },
  { name: 'Bosnia & Herzegovina', fr: 'Bosnie-Herzégovine', isChecked: false },
  { name: 'Botswana', fr: 'Botswana', isChecked: false },
  { name: 'Brazil', fr: 'Brésil', isChecked: false },
  { name: 'Brunei Darussalam', fr: 'Brunei Darussalam', isChecked: false },
  { name: 'Bulgaria', fr: 'Bulgarie', isChecked: false },
  { name: 'Burkina Faso', fr: 'Burkina Faso', isChecked: false },
  { name: 'Myanmar/Burma', fr: 'Myanmar/Birmanie', isChecked: false },
  { name: 'Burundi', fr: 'Burundi', isChecked: false },
  { name: 'Cambodia', fr: 'Cambodge', isChecked: false },
  { name: 'Cameroon', fr: 'Cameroun', isChecked: false },
  { name: 'Canada', fr: 'Canada', isChecked: false },
  { name: 'Cape Verde', fr: 'Cap-Vert', isChecked: false },
  { name: 'Cayman Islands', fr: 'Îles Caïmans', isChecked: false },
  { name: 'Central African Republic', fr: 'République centrafricaine', isChecked: false },
  { name: 'Chad', fr: 'Tchad', isChecked: false },
  { name: 'Chile', fr: 'Chili', isChecked: false },
  { name: 'China', fr: 'Chine', isChecked: false },
  { name: 'Colombia', fr: 'Colombie', isChecked: false },
  { name: 'Comoros', fr: 'Comores', isChecked: false },
  { name: 'Congo', fr: 'Congo', isChecked: false },
  { name: 'Costa Rica', fr: 'Costa Rica', isChecked: false },
  { name: 'Croatia', fr: 'Croatie', isChecked: false },
  { name: 'Cuba', fr: 'Cuba', isChecked: false },
  { name: 'Cyprus', fr: 'Chypre', isChecked: false },
  { name: 'Czech Republic', fr: 'République tchèque', isChecked: false },
  { name: 'Democratic Republic of the Congo', fr: 'République démocratique du Congo', isChecked: false },
  { name: 'Denmark', fr: 'Danemark', isChecked: false },
  { name: 'Djibouti', fr: 'Djibouti', isChecked: false },
  { name: 'Dominica', fr: 'Dominique', isChecked: false },
  { name: 'Dominican Republic', fr: 'République dominicaine', isChecked: false },
  { name: 'Ecuador', fr: 'Équateur', isChecked: false },
  { name: 'Egypt', fr: 'Egypte', isChecked: false },
  { name: 'El Salvador', fr: 'El Salvador', isChecked: false },
  { name: 'Equatorial Guinea', fr: 'Guinée équatoriale', isChecked: false },
  { name: 'Eritrea', fr: 'Érythrée', isChecked: false },
  { name: 'Estonia', fr: 'Estonie', isChecked: false },
  { name: 'Ethiopia', fr: 'Éthiopie', isChecked: false },
  { name: 'Fiji', fr: 'Fidji', isChecked: false },
  { name: 'Finland', fr: 'Finlande', isChecked: false },
  { name: 'France', fr: 'France', isChecked: false },
  { name: 'French Guiana', fr: 'Guyane française', isChecked: false },
  { name: 'Gabon', fr: 'Gabon', isChecked: false },
  { name: 'Gambia', fr: 'Gambie', isChecked: false },
  { name: 'Georgia', fr: 'Géorgie', isChecked: false },
  { name: 'Germany', fr: 'Allemagne', isChecked: false },
  { name: 'Ghana', fr: 'Ghana', isChecked: false },
  { name: 'Great Britain', fr: 'Royaume-Uni', isChecked: false },
  { name: 'Greece', fr: 'Grèce', isChecked: false },
  { name: 'Grenada', fr: 'Grenade', isChecked: false },
  { name: 'Guadeloupe', fr: 'Guadeloupe', isChecked: false },
  { name: 'Guatemala', fr: 'Guatemala', isChecked: false },
  { name: 'Guinea', fr: 'Guinée', isChecked: false },
  { name: 'Guinea-Bissau', fr: 'Guinée-Bissao', isChecked: false },
  { name: 'Guyana', fr: 'Guyana', isChecked: false },
  { name: 'Haiti', fr: 'Haïti', isChecked: false },
  { name: 'Honduras', fr: 'Honduras', isChecked: false },
  { name: 'Hong Kong', fr: 'Hong Kong', isChecked: false },
  { name: 'Hungary', fr: 'Hongrie', isChecked: false },
  { name: 'Iceland', fr: 'Islande', isChecked: false },
  { name: 'India', fr: 'Inde', isChecked: false },
  { name: 'Indonesia', fr: 'Indonésie', isChecked: false },
  { name: 'Iran', fr: 'Iran', isChecked: false },
  { name: 'Iraq', fr: 'Irak', isChecked: false },
  { name: 'Israel', fr: 'Israël', isChecked: false },
  { name: 'Italy', fr: 'Italie', isChecked: false },
  { name: "Ivory Coast", fr: "Côte d' Ivoire", isChecked: false },
  { name: 'Jamaica', fr: 'Jamaïque', isChecked: false },
  { name: 'Japan', fr: 'Japon', isChecked: false },
  { name: 'Jordan', fr: 'Jordanie', isChecked: false },
  { name: 'Kazakhstan', fr: 'Kazakhstan', isChecked: false },
  { name: 'Kenya', fr: 'Kenya', isChecked: false },
  { name: 'Kosovo', fr: 'Kosovo', isChecked: false },
  { name: 'Kuwait', fr: 'Koweït', isChecked: false },
  { name: 'Kyrgyz Republic (Kyrgyzstan)', fr: 'Kirghizistan', isChecked: false },
  { name: 'Laos', fr: 'Laos', isChecked: false },
  { name: 'Latvia', fr: 'Lettonie', isChecked: false },
  { name: 'Lebanon', fr: 'Liban', isChecked: false },
  { name: 'Lesotho', fr: 'Lesotho', isChecked: false },
  { name: 'Liberia', fr: 'Libéria', isChecked: false },
  { name: 'Libya', fr: 'Libye', isChecked: false },
  { name: 'Liechtenstein', fr: 'Liechtenstein', isChecked: false },
  { name: 'Lithuania', fr: 'Lituanie', isChecked: false },
  { name: 'Luxembourg', fr: 'Luxembourg', isChecked: false },
  { name: 'Republic of Macedonia', fr: 'Macédoine', isChecked: false },
  { name: 'Madagascar', fr: 'Madagascar', isChecked: false },
  { name: 'Malawi', fr: 'Malawi', isChecked: false },
  { name: 'Malaysia', fr: 'Malaysie', isChecked: false },
  { name: 'Maldives', fr: 'Maldives', isChecked: false },
  { name: 'Mali', fr: 'Mali', isChecked: false },
  { name: 'Malta', fr: 'Malte', isChecked: false },
  { name: 'Martinique', fr: 'Martinique', isChecked: false },
  { name: 'Mauritania', fr: 'Mauritanie', isChecked: false },
  { name: 'Mauritius', fr: 'Maurice', isChecked: false },
  { name: 'Mayotte', fr: 'Mayotte', isChecked: false },
  { name: 'Mexico', fr: 'Méxique', isChecked: false },
  { name: 'Moldova, Republic of', fr: 'Moldavie', isChecked: false },
  { name: 'Monaco', fr: 'Monaco', isChecked: false },
  { name: 'Mongolia', fr: 'Mongolie', isChecked: false },
  { name: 'Montenegro', fr: 'Monténégro', isChecked: false },
  { name: 'Montserrat', fr: 'Montserrat', isChecked: false },
  { name: 'Morocco', fr: 'Maroc', isChecked: false },
  { name: 'Mozambique', fr: 'Mozambique', isChecked: false },
  { name: 'Namibia', fr: 'Namibie', isChecked: false },
  { name: 'Nepal', fr: 'Népal', isChecked: false },
  { name: 'Netherlands', fr: 'Pays-Bas', isChecked: false },
  { name: 'New Zealand', fr: 'Nouvelle-Zélande', isChecked: false },
  { name: 'Nicaragua', fr: 'Nicaragua', isChecked: false },
  { name: 'Niger', fr: 'Niger', isChecked: false },
  { name: 'Nigeria', fr: 'Nigéria', isChecked: false },
  { name: 'Korea, Democratic Republic of (North Korea)', fr: 'Corée du Nord', isChecked: false },
  { name: 'Norway', fr: 'Norvège', isChecked: false },
  { name: 'Oman', fr: 'Oman', isChecked: false },
  { name: 'Pacific Islands', fr: 'Polynésie française', isChecked: false },
  { name: 'Pakistan', fr: 'Pakistan', isChecked: false },
  { name: 'Panama', fr: 'Panama', isChecked: false },
  { name: 'Papua New Guinea', fr: 'Papouasie Nouvelle Guinée', isChecked: false },
  { name: 'Paraguay', fr: 'Paraguay', isChecked: false },
  { name: 'Peru', fr: 'Pérou', isChecked: false },
  { name: 'Philippines', fr: 'Philippines', isChecked: false },
  { name: 'Poland', fr: 'Pologne', isChecked: false },
  { name: 'Portugal', fr: 'Portugal', isChecked: false },
  { name: 'Puerto Rico', fr: 'Porto Rico', isChecked: false },
  { name: 'Qatar', fr: 'Qatar', isChecked: false },
  { name: 'Reunion', fr: 'Réunion', isChecked: false },
  { name: 'Romania', fr: 'Roumanie', isChecked: false },
  { name: 'Russian Federation', fr: 'Russie', isChecked: false },
  { name: 'Rwanda', fr: 'Rwanda', isChecked: false },
  { name: 'Saint Kitts and Nevis', fr: 'Saint-Kitts-et-Nevis', isChecked: false },
  { name: 'Saint Lucia', fr: 'Sainte-Lucie', isChecked: false },
  { name: "Saint Vincent's & Grenadines", fr: 'Saint-Vincent-et-les-Grenadine', isChecked: false },
  { name: 'Samoa', fr: 'Samoa', isChecked: false },
  { name: 'Sao Tome and Principe', fr: 'Sao Tomé-et-Principe', isChecked: false },
  { name: 'Saudi Arabia', fr: 'Arabie Saoudite', isChecked: false },
  { name: 'Senegal', fr: 'Sénégal', isChecked: false },
  { name: 'Serbia', fr: 'Serbie', isChecked: false },
  { name: 'Seychelles', fr: 'Seychelles', isChecked: false },
  { name: 'Sierra Leone', fr: 'Sierra Leone', isChecked: false },
  { name: 'Singapore', fr: 'Singapour', isChecked: false },
  { name: 'Slovak Republic (Slovakia)', fr: 'Slovaquie', isChecked: false },
  { name: 'Slovenia', fr: 'Slovénie', isChecked: false },
  { name: 'Solomon Islands', fr: 'Îles Salomon', isChecked: false },
  { name: 'Somalia', fr: 'Somalie', isChecked: false },
  { name: 'South Africa', fr: 'Afrique du Sud', isChecked: false },
  { name: 'Korea, Republic of (South Korea)', fr: 'Corée du Sud', isChecked: false },
  { name: 'South Sudan', fr: 'Soudan du Sud', isChecked: false },
  { name: 'Spain', fr: 'Espagne', isChecked: false },
  { name: 'Sri Lanka', fr: 'Sri Lanka', isChecked: false },
  { name: 'Sudan', fr: 'Soudan', isChecked: false },
  { name: 'Suriname', fr: 'Suriname', isChecked: false },
  { name: 'Swaziland', fr: 'Swaziland', isChecked: false },
  { name: 'Sweden', fr: 'Suède', isChecked: false },
  { name: 'Switzerland', fr: 'Suisse', isChecked: false },
  { name: 'Syria', fr: 'Syrie', isChecked: false },
  { name: 'Taiwan', fr: 'Taïwan', isChecked: false },
  { name: 'Tajikistan', fr: 'Tadjikistan', isChecked: false },
  { name: 'Tanzania', fr: 'Tanzanie', isChecked: false },
  { name: 'Thailand', fr: 'Thaïlande', isChecked: false },
  { name: 'Timor Leste', fr: 'Timor Leste', isChecked: false },
  { name: 'Togo', fr: 'Togo', isChecked: false },
  { name: 'Trinidad & Tobago', fr: 'Trinité-et-Tobago', isChecked: false },
  { name: 'Tunisia', fr: 'Tunisie', isChecked: false },
  { name: 'Turkey', fr: 'Turquie', isChecked: false },
  { name: 'Turkmenistan', fr: 'Turkménistan', isChecked: false },
  { name: 'Turks & Caicos Islands', fr: 'Îles Turques-et-Caïques', isChecked: false },
  { name: 'Uganda', fr: 'Ouganda', isChecked: false },
  { name: 'Ukraine', fr: 'Ukraine', isChecked: false },
  { name: 'United Arab Emirates', fr: 'Emirats Arabes Unis', isChecked: false },
  { name: 'United States of America (USA)', fr: 'États Unis', isChecked: false },
  { name: 'Uruguay', fr: 'Uruguay', isChecked: false },
  { name: 'Uzbekistan', fr: 'Ouzbékistan', isChecked: false },
  { name: 'Venezuela', fr: 'Venezuela', isChecked: false },
  { name: 'Vietnam', fr: 'Vietnam', isChecked: false },
  { name: 'Virgin Islands (UK)', fr: 'Îles Vierges britanniques', isChecked: false },
  { name: 'Virgin Islands (US)', fr: 'Îles Vierges américaines', isChecked: false },
  { name: 'Yemen', fr: 'Yémen', isChecked: false },
  { name: 'Zambia', fr: 'Zambie', isChecked: false },
  { name: 'Zimbabwe', fr: 'Zimbabwe', isChecked: false }
];

export const WORLD_WIDE = 'Worldwide';


export const PLAN_OPTIONS = [
  { value: 'inptatientTreatment', label: 'Inpatient treatment care', labelFr: 'Hospitalisation' },
  { value: 'outpatientTreatment', label: 'Outpatient treatment care', labelFr: 'Services consultations externes' },
  { value: 'dentalCare', label: 'Dental care', labelFr: 'Soins dentaires' },
  { value: 'visionCare', label: 'Vision care', labelFr: 'Optique' },
  { value: 'maternityCare', label: 'Maternity care', labelFr: 'Pack maternité' },
  { value: 'medicalAssistance', label: 'Medical Assistance', labelFr: 'Assistance' },
  { value: 'wellnessPreventiveCare', label: 'Wellness Preventive Care', labelFr: 'Prévention' },
];
