import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-right-modal',
  templateUrl: './right-modal.component.html',
  styleUrls: ['./right-modal.component.scss']
})
export class RightModalComponent implements OnInit {

  @Input()
  visible: boolean;

  @Input()
  modalWidth: any;

  @Input()
  modalHeight: any;

  @Output()
  visibleChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  changeVisible(p){        
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

}
