import { ENDPOINTS } from './../../services/api.service';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ErrorHandlerService } from './../../services/error-handler.service';
import { ApiService } from '../../services/api.service';
import {
  REQUEST_PLANS,
  REQUEST_PLANS_SUCCESS,
  CREATE_PLAN,
  CREATE_PLAN_SUCCESS,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS
} from './plans.actions';
import { RAISE_API_ERROR } from '../../app.actions';

@Injectable()
export class PlansEffects {
  @Effect()
  requestPlans: Observable<Action> = this.actions.pipe(
    ofType(REQUEST_PLANS),
    mergeMap((action: any) =>
      this.api.get(ENDPOINTS.plans, action.payload).pipe(
        map(payload => ({ type: REQUEST_PLANS_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  createPlan: Observable<Action> = this.actions.pipe(
    ofType(CREATE_PLAN),
    mergeMap((action: any) =>
      this.api.post(ENDPOINTS.plans, action.payload).pipe(
        map(payload => ({ type: CREATE_PLAN_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  updateProspect: Observable<Action> = this.actions.pipe(
    ofType(UPDATE_PLAN),
    mergeMap((action: any) =>
      this.api.put(ENDPOINTS.plans, action.payload.id, action.payload).pipe(
        map(payload => ({ type: UPDATE_PLAN_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  deleteProspect: Observable<Action> = this.actions.pipe(
    ofType(DELETE_PLAN),
    mergeMap((action: any) =>
      this.api.delete(ENDPOINTS.plans, action.payload).pipe(
        map(deletePayload => ({ type: DELETE_PLAN_SUCCESS, payload: action.payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  constructor(
    private api: ApiService,
    private errorHandlerService: ErrorHandlerService,
    private actions: Actions
  ) {}
}
