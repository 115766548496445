import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCompanies } from './store/companies.reducer';
import { CompanyModel } from './store/companies.models';
import { AppState } from '../app.state';
import { COLUMNS, ACTION_COLUMN } from './companies.constants';
import { REQUEST_COMPANIES, UPDATE_COMPANY, CREATE_COMPANY, DELETE_COMPANY } from './store/companies.actions';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  public columns = COLUMNS;
  public companiesList: Observable<CompanyModel[]>;
  public actionColumn = ACTION_COLUMN;
  public isCompanyFormVisible = false;
  public isEditCompanyForm = false;
  public editCompanyData: CompanyModel = <CompanyModel>{};
  public searchTerm: string;
  public modalId = 'company-form';

  constructor(private store: Store<AppState>) {
    this.companiesList = this.store.pipe(select(selectCompanies));
  }

  ngOnInit() {

    let params = {
      'filter': {
        "where": { "type": "CORPORATE" },
        "order": "name ASC"
      }
    }
    
    this.store.dispatch({ type: REQUEST_COMPANIES, payload: params });
  }
  handleSearchTermChange(term) {
    this.searchTerm = term;
  }
  handleFormSubmit(payload) {
    const actionType = this.isEditCompanyForm ? UPDATE_COMPANY : CREATE_COMPANY;
    this.store.dispatch({ type: actionType, payload });
  }
  handleAddButtonClick() {
    this.isEditCompanyForm = false;
    this.isCompanyFormVisible = true;
    this.editCompanyData = <CompanyModel>{};
  }
  handleEditButtonClick(company) {
    if (company) {
      this.isEditCompanyForm = true;
      this.isCompanyFormVisible = true;
      this.editCompanyData = company;
    }
  }
  handleDelete(id) {
    this.store.dispatch({ type: DELETE_COMPANY, payload: id });
  }
}
