export const COLUMNS = [
  {
    label: 'User Type',
    labelFr: 'Type',
    dataKey: 'type'
  },
  {
    label: 'First Name',
    labelFr: 'Prénom',
    dataKey: 'firstName'
  },
  {
    label: 'Last Name',
    labelFr: 'Nom',
    dataKey: 'lastName',
    className: 'column-lastname'
  },
  {
    label: 'Email',
    labelFr: 'Email',
    dataKey: 'email'
  },
  {
    label: 'Status',
    labelFr: 'Statut',
    dataKey: 'status'
  }
];

export const ACTION_COLUMN = {
  label: 'Settings',
  labelFr: 'Paramètres',
  buttonIcon: 'fa fa-edit',
};

export const STATUSES = {
  active: 'Active',
  inactive: 'Inactive'
};
