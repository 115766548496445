import { REQUEST_COMPANIES } from './../../../companies/store/companies.actions';
import { CompanyModel } from './../../../companies/store/companies.models';
import { selectCompanies } from './../../../companies/store/companies.reducer';
import { Observable } from 'rxjs';
import { AppState } from './../../../app.state';
import { Store, select } from '@ngrx/store';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit {
  @Output()
  public selectChange = new EventEmitter();
  public company = '';
  public companiesList: Observable<CompanyModel[]>;

  constructor(private store: Store<AppState>, public translate: TranslateService) {
    this.companiesList = this.store.pipe(select(selectCompanies));
  }

  ngOnInit() {
    let params = {
      'filter': {
        "where": { "type": "CORPORATE" },
        "order": "name ASC"
      }
    }

    this.store.dispatch({ type: REQUEST_COMPANIES, payload: params });
  }

  onChangeCompany() {
    this.selectChange.emit(this.company);
  }
}
