import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IColumn } from './table.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  @Input() columns: Array<IColumn>;
  @Input() dataSource: Array<any>;
  @Input() actionColumnConfig: any;
  @Input() noRecords: any;
  @Input() modalDataTarget: any;
  @Input() modalDataTogle: any;
  @Output() actionButtonClick = new EventEmitter();
  @Output() otherActions = new EventEmitter();
  @Input() fixedSizeCol = false;
  constructor(public translate: TranslateService) { }

  handleActionButtonClick(row) {
    this.actionButtonClick.emit(row);
  }

  handleOtherActionButtonClick(action, row?: any) {
    if (row.action == 'edit') {
      this.actionButtonClick.emit(row);
    }
    if (row) {
      this.otherActions.emit({ 'method': action, 'param': row.id });
    } else {
      this.otherActions.emit({ 'method': action })
    }
  }

  resolve(path, obj) {
    return path.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : null
    }, obj || self)
  }
}
