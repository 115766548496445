import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Modules */
import { CommonModule } from '@angular/common';

// Components
import { GuaranteesComponent } from './guarantees.component';
import { GuaranteeFormComponent } from './components/guarantee-form/guarantee-form.component';
import { SelectPlansComponent } from './components/select-plans/select-plans.component';

/* Providers */
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [
  { path: 'guarantees', component: GuaranteesComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [GuaranteesComponent, GuaranteeFormComponent, SelectPlansComponent]
})
export class GuaranteesModule {}
