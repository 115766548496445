import { Router, ActivatedRoute } from '@angular/router';
import { FILE_CONTAINERS, ApiService } from './../services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-documentation',
  templateUrl: './company-documentation.component.html',
  styleUrls: ['./company-documentation.component.scss']
})
export class CompanyDocumentationComponent implements OnInit {
  company: any;
  companyId: any;
  comparisonId: any;
  comparison: any;
  logoContainer = FILE_CONTAINERS.logos;
  documentContainer = FILE_CONTAINERS.documentation;
  lang: any;
  currency: any;
  cfe: any;

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.companyId = this.route.snapshot.paramMap.get('companyId');

    this.route.queryParams.subscribe(params => {
      if (!this.companyId) {
        this.companyId = params['companyId'];
      }
      this.lang = params['lang'];
      this.currency = params['currencyId'];
      this.cfe = params['cfe'];
      this.comparisonId = params['comparisonId'];
      console.log(params);
    });

    let params =
    {
      'filter': {
        'include': "documentations"
      }
    }

    if (this.comparisonId) {
      this.api.get(`/Comparisons/${this.comparisonId}`).subscribe(
        (comparison: any) => {
          this.comparison = comparison
        })
    }

    this.api.get(`/Companies/${this.companyId}`, params).subscribe((company: any) => {

      Object.keys(company).map((key, index) => {
        if (key == 'logo') {
          const fileName = company[key]
          company[key] = this.api.downloadUrl({ container: this.logoContainer, fileName });
        }
        if (key == 'documentations') {
          company[key].map((e) => {
            const fileName = e.path
            e.path = this.api.downloadUrl({ container: this.documentContainer, fileName });
            return e;
          })

          if (this.lang || this.cfe) {
            let isTrueSet = (this.cfe === 'true');

            company.documentations = company[key].filter((e) => {
              if ((e.locale.toUpperCase() == this.lang.toUpperCase())
                && (e.locale.toUpperCase() !== 'FR' || e.cfe == isTrueSet)
              ) {
                return true;
              }

            })
            console.log({ company })
          }
        }
      });

      this.company = company

    })
  }

  download(container, fileName) {
    const url = this.api.downloadUrl({ container: container, fileName: fileName });
    window.open(url);
  }

}
