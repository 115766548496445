import { ApiService } from './../../../services/api.service';
import { CompanyModel } from './../../store/companies.models';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { pickBy } from 'lodash';
import { identity } from 'rxjs';
import { FILE_CONTAINERS } from '../../../services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnChanges {
  @Input()
  isEditCompany = false;
  @Input()
  visible: boolean;
  @Input()
  modalId;
  @Input()
  company: CompanyModel;
  @Output()
  visibleChange = new EventEmitter();
  @Output()
  formSubmit = new EventEmitter();
  @Output()
  deleteEntity = new EventEmitter();
  companyForm: FormGroup;
  logoContainer = FILE_CONTAINERS.logos;
  documentContainer = FILE_CONTAINERS.documentation;
  companypage = true;
  aLanguaje = [
    'EN',
    'FR'
  ]
  language = 'EN';
  type = ['INDIVIDUAL', 'CORPORATE']
  cfe = false;
  documents: any;
  filteredDocuments: any;
  documentName: any;
  companyLogo = null;

  constructor(private fb: FormBuilder, private api: ApiService, private translate: TranslateService) { }
  companyFormWithLogo = !this.isEditCompany;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.company && changes.company.currentValue !== changes.company.previousValue) {
      this.initializeForm();
      if (this.company.id) {
        this.loadDocuments();
      }
    }
  }

  initializeForm() {
    this.companypage = true;
    this.companyForm = this.fb.group({
      name: [this.company.name || '', Validators.required],
      description: [this.company.description || ''],
      type: ['CORPORATE'],
      logo: [this.company.logo]
    });
    this.companyLogo = this.company.logoAbsolutePath;
  }

  handleUploadLogoSuccess(logoName) {
    this.companyLogo = logoName;
    this.company.logoAbsolutePath = logoName;
  }

  handleSubmit() {
    if (this.companyForm.valid) {
      const userData = pickBy(
        {
          ...this.companyForm.value,
          id: this.company.id
        },
        identity
      );
      this.formSubmit.emit(userData);
      this.closeForm();
    }
  }

  handleDelete() {
    this.deleteEntity.emit(this.company.id);
    this.closeForm();
  }

  closeForm() {
    this.visible = false;
    this.companypage = true;
    this.visibleChange.emit(false);
  }

  get logoName() {
    return this.companyForm && this.companyForm.get('logo').value;
  }

  get submitButtonText() {
    return this.isEditCompany ? this.translate.instant('COMPANIES.edit_company') : this.translate.instant('COMPANIES.add_company');
  }
  get titleText() {
    return this.isEditCompany ? this.translate.instant('COMPANIES.edit_company') : this.translate.instant('COMPANIES.add_company');
  }

  loadDocuments() {

    this.api.get(`/Companies/${this.company.id}/documentations`).subscribe(
      (documents: any) => {
        console.log('docs', documents)

        let data = documents.map((e) => {
          const fileName = e.path
          e.file = this.api.downloadUrl({ container: this.documentContainer, fileName });
          return e;
        })

        this.documents = data
        this.filteredDocuments = [].concat(data)
        this.filter()
      },
      err => {
        if (err.status === 401) {
          console.error('Unauthorized')
          console.error({ err })
        } else {
          console.error({ err })
        }
      })
  }

  manageDocuments() {
    this.companypage = false;
  }

  filter() {
    this.filteredDocuments = []

    if (this.documents && this.documents.length) {
      this.filterDocs()
    }
    if (this.language == 'EN') {
      this.cfe = false
      this.filterDocs()
    }
  }

  filterDocs() {
    this.filteredDocuments = this.documents.filter(item => {

      if ((item.locale.toUpperCase() == this.language.toUpperCase())
        && (item.cfe == this.cfe)
      ) {
        return true;
      }

    });
  }

  get documentFileName() {
    return 'atutu'
  }

  handleUploadDocumentSuccess(documentName) {
    
    this.loadDocuments()
    this.documentName = '';

  }

  deleteDocument(documentId) {
    this.api.delete('/CompanyDocuments', documentId).subscribe(
      (deletedDoc: any) => {
        console.log({ deletedDoc })
        this.loadDocuments()
        this.documentName = ''
      },
      err => {
        if (err.status === 401) {
          console.error('Unauthorized')
          console.error({ err })
        } else {
          console.error({ err })
        }
      }
    );
  }

  backButton() {
    this.companypage = true;
  }


}
