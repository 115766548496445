import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserItemModel } from '../../users.component';
import { pickBy, identity } from 'lodash';
import { RoleModel } from '../../store/users.models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnChanges {
  @Input()
  isEditUser = false;
  @Input()
  user: UserItemModel = <UserItemModel>{};
  @Input()
  visible: boolean;
  @Input()
  modalId;
  @Input()
  roles: RoleModel[];
  @Output()
  visibleChange = new EventEmitter();
  @Output()
  formSubmit = new EventEmitter();
  @Output()
  deleteEntity = new EventEmitter();
  userForm: FormGroup;
  showPassword = false;

  constructor(private fb: FormBuilder, private translate: TranslateService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && changes.user.currentValue !== changes.user.previousValue) {
      this.initializeForm();
    }
  }

  initializeForm() {
    this.userForm = this.fb.group({
      firstName: [this.user.firstName || '', Validators.required],
      lastName: [this.user.lastName || '', Validators.required],
      enabled: [this.user.enabled],
      email: [this.user.email || '', [Validators.required, Validators.email]],
      type: [this.user.type || '', Validators.required],
      password: ['', this.isEditUser ? [] : Validators.required]
    });
  }

  displayPassword() {
    this.showPassword = !this.showPassword
  }

  handleSubmit() {
    if (this.userForm.valid) {

      this.userForm.value.enabled = this.userForm.value.enabled ? true : 'false';
      console.log('userForm', this.userForm.value)
      const userData = pickBy(
        {
          ...this.userForm.value,
          id: this.user.id
        },
        identity
      );
      this.formSubmit.emit(userData);
      this.closeForm();
    }
  }

  handleDelete() {
    this.visible = false;
    this.visibleChange.emit(false);
    this.deleteEntity.emit(this.user.id);
  }

  closeForm() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  get submitButtonText() {
    return this.isEditUser ? this.translate.instant('USERS.edit_user') : this.translate.instant('USERS.ADD_USER');
  }

  get titleText() {
    return this.isEditUser ? this.translate.instant('USERS.edit_user') : this.translate.instant('USERS.ADD_USER');
  }
}
