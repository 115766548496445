import { Action } from '@ngrx/store';

export interface ToastModel {
    show: boolean;
    message?: string;
}

export const TOGGLE_TOAST = 'TOGGLE_TOAST';
export const RAISE_API_ERROR = 'API_ERROR';

export class ToggleToast implements Action {
    readonly type = TOGGLE_TOAST;
    constructor(public payload: ToastModel) {}
}

export class RaiseApiError implements Action {
    readonly type = RAISE_API_ERROR;
    constructor(public payload: String) {}
}

export type Actions = ToggleToast | RaiseApiError;
