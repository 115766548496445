import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {
  URL = environment.URL;
  
  @Input()
  translate: any
  @Input()
  clientConfigData: any

  constructor() { }

  ngOnInit() {
  }

  public switchLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
  }

}
