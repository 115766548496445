import { Router } from '@angular/router';
import { REQUEST_PROSPECTS, CREATE_PROSPECT, UPDATE_PROSPECT, DELETE_PROSPECT } from './store/prospects.actions';
import { ProspectModel } from './store/prospects.models';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app.state';
import { selectProspects } from './store/prospects.reducer';
import { COLUMNS, ACTION_COLUMN } from './prospects.constants';

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.scss']
})
export class ProspectsComponent implements OnInit {

  public columns = COLUMNS;
  public prospectsList: Observable<ProspectModel[]>;
  public actionColumn = ACTION_COLUMN;
  public isProspectFormVisible = false;
  public isQuotesVisible = false;
  public isEditProspectForm = false;
  public editProspectData: ProspectModel = <ProspectModel>{};
  public searchTerm: string;
  public modalId = 'comparison-form'

  constructor(private store: Store<AppState>, private router: Router) {
    this.prospectsList = this.store.pipe(select(selectProspects));
  }

  ngOnInit() {

    let params =
    {
      "filter": {
        "where": {
          "temporary": false
        }
      }
    }

    this.store.dispatch({ type: REQUEST_PROSPECTS, payload: params });
  }

  handleFormSubmit(payload) {
    const actionType = this.isEditProspectForm ? UPDATE_PROSPECT : CREATE_PROSPECT;
    this.store.dispatch({ type: actionType, payload });
  }

  handleSearchTermChange(term) {
    this.searchTerm = term;
  }

  handleEditButtonClick(data) {
    if (data) {
      this.isEditProspectForm = true;
      this.isProspectFormVisible = true;
      this.editProspectData = data;
    }
  }

  handleAddButtonClick() {
    this.isEditProspectForm = false;
    this.isProspectFormVisible = true;
    this.editProspectData = <ProspectModel>{};
  }

  handleDelete(id) {
    this.store.dispatch({ type: DELETE_PROSPECT, payload: id });
  }

  delete(id){
    this.handleDelete(id);
  }

  handleOtherActions(data) {
    if (data) {      
      let methodName = data.method;
      if (this[methodName]) {
        // method exists on the component
        let param = data.param;
        this[methodName](param); // call it
      }
    }
  }

  compare(id) {
    this.router.navigateByUrl('/compare/' + id);
  }

  quotes(data) {
    this.isQuotesVisible = true
    this.editProspectData = data;
  }

}
