import { ProspectModel } from './prospects.models';
import { Action } from '@ngrx/store';

export const REQUEST_PROSPECTS = 'REQUEST_PROSPECTS',
  REQUEST_PROSPECTS_SUCCESS = 'REQUEST_PROSPECTS_SUCCESS',
  CREATE_PROSPECT = 'CREATE_PROSPECT',
  CREATE_PROSPECT_SUCCESS = 'CREATE_PROSPECT_SUCCESS',
  UPDATE_PROSPECT = 'UPDATE_PROSPECT',
  UPDATE_PROSPECT_SUCCESS = 'UPDATE_PROSPECT_SUCCESS',
  DELETE_PROSPECT = 'DELETE_PROSPECT',
  DELETE_PROSPECT_SUCCESS = 'DELETE_PROSPECT_SUCCESS';

export class RequestProspects implements Action {
  readonly type = REQUEST_PROSPECTS;
  constructor() {}
}
export class RequestProspectsSuccess implements Action {
  readonly type = REQUEST_PROSPECTS_SUCCESS;
  constructor(public payload: ProspectModel[]) {}
}

export class CreateProspect implements Action {
  readonly type = CREATE_PROSPECT;
  constructor(public payload: { body: ProspectModel }) {}
}

export class CreateProspectSuccess implements Action {
  readonly type = CREATE_PROSPECT_SUCCESS;
  constructor(public payload: ProspectModel[]) {}
}

export class UpdateProspect implements Action {
  readonly type = UPDATE_PROSPECT;
  constructor(public payload: ProspectModel) {}
}

export class UpdateProspectSuccess implements Action {
  readonly type = UPDATE_PROSPECT_SUCCESS;
  constructor(public payload: ProspectModel) {}
}

export class DeleteProspect implements Action {
  readonly type = DELETE_PROSPECT;
  constructor(public payload: ProspectModel) {}
}

export class DeleteProspectSuccess implements Action {
  readonly type = DELETE_PROSPECT_SUCCESS;
  constructor(public payload: ProspectModel) {}
}

export type Actions =
  | RequestProspects
  | RequestProspectsSuccess
  | CreateProspect
  | CreateProspectSuccess
  | UpdateProspect
  | UpdateProspectSuccess
  | DeleteProspect
  | DeleteProspectSuccess;
