export const COLUMNS = [  
  {
    label: 'BENEFITS ENGLISH',
    labelFr: 'Garanties - ANGLAIS',
    dataKey: 'guarantee.enTitle'
  },
  {
    label: 'BENEFITS FRENCH',
    labelFr: 'Garanties - FRANÇAIS',
    dataKey: 'guarantee.frTitle'
  },
  {
    label: 'ENGLISH DETAIL',
    labelFr: 'DETAILS ANGLAIS',
    dataKey: 'enDetail'
  },
  {
    label: 'FRENCH DETAIL',
    labelFr: 'DETAILS FRANÇAIS',
    dataKey: 'frDetail'
  }
];

export const ACTION_COLUMN = {
  label: 'Settings',
  labelFr: 'Settings',
  buttonIcon: 'fa fa-edit',
};
