import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';

const API_URL = environment.apiUrl;

export const ENDPOINTS = {
  users: '/People',
  roles: '/Roles',
  plans: '/InsurancePlans',
  companies: '/Companies',
  prospects: '/CorporateProspects',
  guarantees: '/planGuarantees',
  storage: '/Storages'
};
export const FILE_CONTAINERS = {
  logos: 'logo',
  prospectsCensus: 'corporateProspectsCensus',
  prospectsLifeProtection: 'corporateProspectsLifeProtection',
  prospectsJoiners: 'corporateProspectsJoiners',
  downloads: 'downloads',
  guarantees: 'guarantees',
  documentation: 'document'
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router) { }

  private get accessTokenAppend() {
    return this.accessToken ? `?access_token=${this.accessToken}` : '';
  }

  private get accessToken(): string {
    return localStorage.getItem('token');
  }

  private set accessToken(token: string) {
    localStorage.setItem('token', token);
  }

  public initSession(token: string) {
    this.accessToken = token;
  }
  public isAuthenticated() {
    return this.accessToken;
  }
  public logout() {

    if (!this.accessToken) {
      this.eraseCredentials();
      return false;
    }

    this.post(`${ENDPOINTS.users}/logout`, {}).subscribe(
      (data) => {
        this.eraseCredentials()
      },
      (error) => {
        console.error('logout', error)
        this.eraseCredentials()
      }
    );

  }

  eraseCredentials() {
    this.accessToken = null;
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.removeItem('userRole')
    this.router.navigateByUrl('/login');
  }
  public login(email: string, password: string) {
    return this.http.post(`${API_URL}${ENDPOINTS.users}/login`, { email, password });
  }

  public get(endpoint: string, reqParams?: any) {

    let params = new HttpParams()

    // Support easy query params for GET requests

    for (let k in reqParams) {

      if (k == 'fields' || k == 'filters' || k == 'filter') {
        params = params.set(k, JSON.stringify(reqParams[k]));
      } else {
        params = params.set(k, reqParams[k]);
      }
    }

    return this.http.get(`${API_URL}${endpoint}`, { params });
  }

  public post(endpoint: string, body) {
    return this.http.post(`${API_URL}${endpoint}`, body);
  }
  public put(endpoint: string, id: string, body) {
    return this.http.put(`${API_URL}${endpoint}/${id}`, body);
  }
  public patch(endpoint: string, id: string, body) {
    return this.http.patch(`${API_URL}${endpoint}/${id}`, body);
  }
  public delete(endpoint: string, id: string) {
    return this.http.delete(`${API_URL}${endpoint}/${id}`);
  }
  public deleteFile(container: string, fileName: string) {
    return this.http.delete(`${API_URL}/Storages/${container}/files/${fileName}`);
  }

  public getGuarantee(id: string) {
    let filter = {
      include: {
        relation: 'guarantee',
        scope: {
          fields: ['enTitle', 'frTitle']
        }
      },
    }
    return this.http.get(`${API_URL}${ENDPOINTS.plans}/${id}${ENDPOINTS.guarantees.toLowerCase()}?filter=${JSON.stringify(filter)}`);
  }
  public upload(params) {
    const input = new FormData();
    input.append('file', params.file);
    if (params.planId) {
      input.append('planId', params.planId);
    }
    if (params.uploadType) {
      input.append('uploadType', params.uploadType);
    }
    if (params.companyId) {
      input.append('companyId', params.companyId);
    }
    if (params.cfe) {
      input.append('cfe', params.cfe);
    }
    if (params.name) {
      input.append('name', params.name);
    }
    if (params.locale) {
      input.append('locale', params.locale);
    }
    return this.http.post(`${API_URL}${ENDPOINTS.storage}/tmp/upload`, input);
  }

  public downloadUrl({ container, fileName }) {
    return `${API_URL}${ENDPOINTS.storage}/${container}/download/${fileName}`;
  }

  public downloadStatic(fileName) {
    return `${API_URL}/../static/${fileName}`;
  }

  public getDownloadUrl(endpoint: string) {
    return `${API_URL}${endpoint}`;
  }

  public getUserRole(userId) {
    return this.http.get(`${API_URL}${ENDPOINTS.users}/${userId}`);
  }

  public getConfigData() {    

    return this.http.get(`${API_URL}/Admins/getServers`);
  }

}
