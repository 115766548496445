import { ApiService } from './../../../services/api.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ProspectModel } from '../../store/prospects.models';

@Component({
  selector: 'app-comparison-quotes',
  templateUrl: './comparison-quotes.component.html',
  styleUrls: ['./comparison-quotes.component.scss']
})
export class ComparisonQuotesComponent implements OnChanges {

  @Input()
  visible: boolean;
  @Input()
  modalId;
  @Input()
  prospect: ProspectModel;
  @Output()
  visibleChange = new EventEmitter();

  quotes = []

  constructor(
    private api: ApiService,
  ) {

  }

  loadQuotes(prospectId?: Number) {

    let params = null
    if (prospectId && JSON.stringify(prospectId) != '{}') {
      params =
      {
        "filter": {
          "where": {
            "corporateProspectId": prospectId
          }
        }
      }
    }

    this.api.get(`/Comparisons`, params).subscribe(
      (quotes: any) => {
        this.quotes = quotes
      }
      , (err) => {
        console.error(err)
      })

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.prospect && changes.prospect.currentValue !== changes.prospect.previousValue) {

      if (changes.prospect.firstChange) {
        return
      } else {
        this.loadQuotes(changes.prospect.currentValue);
      }
    }

  }


  closeForm() {
    this.visible = false;
    this.visibleChange.emit(false);
  }


}
