import { Observable, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private router: Router, private apiService: ApiService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available

    let token = localStorage.getItem(TOKEN_KEY)

    request = request.clone({
      setHeaders: {
        Authorization: `${token}`,
      }
    });

    return next.handle(request)
     .pipe(
        catchError(error => {
          if (error.status === 401 || error.status === 403) {
            // handle error
            this.apiService.logout();
          }
          return throwError(error);
        })
     );

  }

}
