import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ENDPOINTS } from './../../services/api.service';
import { ApiService } from '../../services/api.service';
import {
  REQUEST_PROSPECTS,
  REQUEST_PROSPECTS_SUCCESS,
  CREATE_PROSPECT,
  CREATE_PROSPECT_SUCCESS,
  UPDATE_PROSPECT,
  UPDATE_PROSPECT_SUCCESS,
  DELETE_PROSPECT,
  DELETE_PROSPECT_SUCCESS
} from './prospects.actions';
import { RAISE_API_ERROR } from '../../app.actions';

@Injectable()
export class ProspectsEffects {
  @Effect()
  requestProspects: Observable<Action> = this.actions.pipe(
    ofType(REQUEST_PROSPECTS),
    mergeMap((action: any) =>
      this.api.get(ENDPOINTS.prospects, action.payload).pipe(
        map(payload => ({ type: REQUEST_PROSPECTS_SUCCESS, payload })),
        catchError(error => {
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  createProspect: Observable<Action> = this.actions.pipe(
    ofType(CREATE_PROSPECT),
    mergeMap((action: any) =>
      this.api.post(ENDPOINTS.prospects, action.payload).pipe(
        map(payload => ({ type: CREATE_PROSPECT_SUCCESS, payload })),
        catchError(error => {
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  updateProspect: Observable<Action> = this.actions.pipe(
    ofType(UPDATE_PROSPECT),
    mergeMap((action: any) =>
      this.api.put(ENDPOINTS.prospects, action.payload.id, action.payload).pipe(
        map(payload => ({ type: UPDATE_PROSPECT_SUCCESS, payload })),
        catchError(error => {
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  deleteProspect: Observable<Action> = this.actions.pipe(
    ofType(DELETE_PROSPECT),
    mergeMap((action: any) =>
      this.api.delete(ENDPOINTS.prospects, action.payload).pipe(
        map(deletePayload => ({ type: DELETE_PROSPECT_SUCCESS, payload: action.payload })),
        catchError(error => {
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  constructor(private api: ApiService, private actions: Actions) {}
}