import { ToastModel } from './../app.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { TOGGLE_TOAST } from '../app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from './api.service';

const STATUS_MESSAGE_MAP = {
  0: 'An error has occurred please retry in a few minutes',
  401: 'Please login'
};

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private store: Store<AppState>, private api: ApiService) { }

  private getMessage(err) {
    const errors = err.details && err.details.messages;
    if (errors) {
      if (errors.email) {
        return errors.email[0];
      }
    }
    return STATUS_MESSAGE_MAP[err.status];
  }

  public apiError(err: HttpErrorResponse): void {
    if (err) {
      if (err.status === 401) {
        this.api.logout();
      } else {
        const message = this.getMessage(err.error.error);
        const toast: ToastModel = {
          show: true,
          message: message || STATUS_MESSAGE_MAP[0],
        };
        this.store.dispatch({ type: TOGGLE_TOAST, payload: toast});
      }
    }
  }
}
