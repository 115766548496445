import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService, ENDPOINTS } from '../../services/api.service';
import {
  REQUEST_GUARANTEES,
  REQUEST_GUARANTEES_SUCCESS,
  CREATE_GUARANTEE,
  CREATE_GUARANTEE_SUCCESS,
  UPDATE_GUARANTEE,
  UPDATE_GUARANTEE_SUCCESS
} from './guarantee.actions';
import { RAISE_API_ERROR } from '../../app.actions';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Injectable()
export class GuaranteesEffects {
  @Effect()
  requestGuarantees: Observable<Action> = this.actions.pipe(
    ofType(REQUEST_GUARANTEES),
    mergeMap((action: any) =>
      this.api.getGuarantee(action.payload).pipe(
        map(payload => ({ type: REQUEST_GUARANTEES_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  createGuarantee: Observable<Action> = this.actions.pipe(
    ofType(CREATE_GUARANTEE),
    mergeMap((action: any) =>
      this.api.post(ENDPOINTS.guarantees, action.payload).pipe(
        map(payload => ({ type: CREATE_GUARANTEE_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  updateGuarantee: Observable<Action> = this.actions.pipe(
    ofType(UPDATE_GUARANTEE),
    mergeMap((action: any) =>
      this.api.put(ENDPOINTS.guarantees, action.payload.id, action.payload).pipe(
        map((payload) => {
          payload['guarantee'] = {
            enTitle: payload['enTitle'],
            frTitle: payload['frTitle'],
          }
          return ({ type: UPDATE_GUARANTEE_SUCCESS, payload })
        },
          catchError(error => {
            this.errorHandlerService.apiError(error);
            return of({ type: RAISE_API_ERROR, payload: error.message });
          })
        )
      )
    ));

  constructor(
    private api: ApiService,
    private errorHandlerService: ErrorHandlerService,
    private actions: Actions
  ) { }
}
