import { Component, OnInit, ElementRef, Output, ViewChild, Input } from '@angular/core';
import { EventEmitter } from 'events';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [NgbActiveModal]
})
export class ModalComponent implements OnInit {

  @ViewChild('btnClose') btnClose: ElementRef


  @Output() saved = new EventEmitter();


  @Input() modalId;
  @Input() title;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
