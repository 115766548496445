import { Router, ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from './../../../services/api.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css'],
})
export class ForgotComponent {

  forgotForm: FormGroup;
  authFailed = false;
  accessToken: string;
  tokenValid = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder) {

    this.initializeForgotForm()

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let accessToken = params['access_token'];
      if (accessToken) {
        this.api.initSession(accessToken);
        this.tokenValid = true
      }
    });
  }


  initializeForgotForm() {
    this.forgotForm = this.fb.group({
      password: ['', [Validators.required]]
    });
  }

  handleSubmit() {
    if (this.forgotForm.valid && this.tokenValid) {

      let body = {
        newPassword: this.forgotForm.value.password
      }

      this.api.post('/People/reset-password', body).subscribe(
        (res: any) => {
          this.api.logout();
          this.router.navigateByUrl('/login');
        },
        err => {
          if (err.status === 401) {
            this.authFailed = true;
          }
        }
      );
    }
  }
}
