import { ENDPOINTS } from './../../services/api.service';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ErrorHandlerService } from './../../services/error-handler.service';
import { ApiService } from '../../services/api.service';
import {
  REQUEST_USERS,
  REQUEST_USERS_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  REQUEST_ROLES,
  REQUEST_ROLES_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS
} from './users.actions';
import { RAISE_API_ERROR } from '../../app.actions';

@Injectable()
export class UsersEffects {
  @Effect()
  requestUsers: Observable<Action> = this.actions.pipe(
    ofType(REQUEST_USERS),
    mergeMap((action: any) =>
      this.api.get(ENDPOINTS.users, action.payload).pipe(
        map(payload => ({ type: REQUEST_USERS_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  createUser: Observable<Action> = this.actions.pipe(
    ofType(CREATE_USER),
    mergeMap((action: any) =>
      this.api.post(ENDPOINTS.users, action.payload).pipe(
        map(payload => ({ type: CREATE_USER_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  updateUser: Observable<Action> = this.actions.pipe(
    ofType(UPDATE_USER),
    mergeMap((action: any) =>
      this.api.patch(ENDPOINTS.users, action.payload.id, action.payload).pipe(
        map(payload => ({ type: UPDATE_USER_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  requestRoles: Observable<Action> = this.actions.pipe(
    ofType(REQUEST_ROLES),
    mergeMap((action: any) =>
      this.api.get(ENDPOINTS.roles, action.payload).pipe(
        map(payload => ({ type: REQUEST_ROLES_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  deleteUser: Observable<Action> = this.actions.pipe(
    ofType(DELETE_USER),
    mergeMap((action: any) =>
      this.api.delete(ENDPOINTS.users, action.payload).pipe(
        map(deletePayload => ({ type: DELETE_USER_SUCCESS, payload: action.payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  constructor(
    private api: ApiService,
    private errorHandlerService: ErrorHandlerService,
    private actions: Actions
  ) {}
}
