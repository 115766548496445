import { Action } from '@ngrx/store';
import { GuaranteeModel, GuaranteeFilter } from './guarantee.models';

export const REQUEST_GUARANTEES = 'REQUEST_GUARANTEES',
  REQUEST_GUARANTEES_SUCCESS = 'REQUEST_GUARANTEES_SUCCESS',
  RESET_GUARANTEES = 'RESET_GUARANTEES',
  CREATE_GUARANTEE = 'CREATE_GUARANTEE',
  CREATE_GUARANTEE_SUCCESS = 'CREATE_GUARANTEE_SUCCESS',
  UPDATE_GUARANTEE = 'UPDATE_GUARANTEE',
  UPDATE_GUARANTEE_SUCCESS = 'UPDATE_GUARANTEE_SUCCESS',
  FILTER_GUARANTEES = 'FILTER_GUARANTEES';

export class RequestGuarantee implements Action {
  readonly type = REQUEST_GUARANTEES;
  constructor() {}
}
export class RequestGuaranteeSuccess implements Action {
  readonly type = REQUEST_GUARANTEES_SUCCESS;
  constructor(public payload: GuaranteeModel[]) {}
}
export class ResetGuarantees implements Action {
  readonly type = RESET_GUARANTEES;
  constructor() {}
}

export class CreateGuarantee implements Action {
  readonly type = CREATE_GUARANTEE;
  constructor(public payload: { body: GuaranteeModel }) {}
}

export class CreateGuaranteeSuccess implements Action {
  readonly type = CREATE_GUARANTEE_SUCCESS;
  constructor(public payload: GuaranteeModel[]) {}
}

export class UpdateGuarantee implements Action {
  readonly type = UPDATE_GUARANTEE;
  constructor(public payload: GuaranteeModel) {}
}

export class UpdateGuaranteeSuccess implements Action {
  readonly type = UPDATE_GUARANTEE_SUCCESS;
  constructor(public payload: GuaranteeModel) {}
}

export class FilterGuarantees implements Action {
  readonly type = FILTER_GUARANTEES;
  constructor(public payload: GuaranteeFilter) {}
}

export type Actions =
  | RequestGuarantee
  | RequestGuaranteeSuccess
  | ResetGuarantees
  | CreateGuarantee
  | CreateGuaranteeSuccess
  | UpdateGuarantee
  | UpdateGuaranteeSuccess
  | FilterGuarantees;
