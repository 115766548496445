import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Modules */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';

/* Components */
import { CompaniesComponent } from './companies.component';
import { CompanyFormComponent } from './components/company-form/company-form.component';

/* Providers */
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [
  { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [CompaniesComponent, CompanyFormComponent]
})
export class CompaniesModule {}
