import { Action } from '@ngrx/store';
import { CompanyModel } from './companies.models';

export const REQUEST_COMPANIES = 'REQUEST_COMPANIES',
  REQUEST_COMPANIES_SUCCESS = 'REQUEST_COMPANIES_SUCCESS',
  CREATE_COMPANY = 'CREATE_COMPANY',
  CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS',
  UPDATE_COMPANY = 'UPDATE_COMPANY',
  UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS',
  DELETE_COMPANY = 'DELETE_COMPANY',
  DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';

export class RequestCompanies implements Action {
  readonly type = REQUEST_COMPANIES;
  constructor() {}
}
export class RequestCompaniesSuccess implements Action {
  readonly type = REQUEST_COMPANIES_SUCCESS;
  constructor(public payload: CompanyModel[]) {}
}

export class CreateCompany implements Action {
  readonly type = CREATE_COMPANY;
  constructor(public payload: { body: CompanyModel }) {}
}

export class CreateCompanySuccess implements Action {
  readonly type = CREATE_COMPANY_SUCCESS;
  constructor(public payload: CompanyModel[]) {}
}

export class UpdateCompany implements Action {
  readonly type = UPDATE_COMPANY;
  constructor(public payload: CompanyModel) {}
}

export class UpdateCompanySuccess implements Action {
  readonly type = UPDATE_COMPANY_SUCCESS;
  constructor(public payload: CompanyModel) {}
}

export class DeleteCompany implements Action {
  readonly type = DELETE_COMPANY;
  constructor(public payload: CompanyModel) {}
}

export class DeleteCompanySuccess implements Action {
  readonly type = DELETE_COMPANY_SUCCESS;
  constructor(public payload: CompanyModel) {}
}

export type Actions =
  | RequestCompanies
  | RequestCompaniesSuccess
  | CreateCompany
  | CreateCompanySuccess
  | UpdateCompany
  | UpdateCompanySuccess
  | DeleteCompany
  | DeleteCompanySuccess;
