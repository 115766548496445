import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Modules */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

/* Components */
import { BreakdownComponent } from './breakdown.component';

/* Providers */
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [{ path: 'breakdown/:id', component: BreakdownComponent, canActivate: [AuthGuardService] }];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(routes),
    BrowserModule,
    CKEditorModule
  ],
  declarations: [BreakdownComponent]
})
export class BreakdownModule {}
