import { ToastModel } from './../../../app.actions';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { TOGGLE_TOAST } from '../../../app.actions';

const TIME_TO_CLOSE = 8000;

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  @Input() message: string;
  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.startCountDown();
  }

  private startCountDown() {
    setTimeout(this.closeToast.bind(this), TIME_TO_CLOSE);
  }

  public closeToast() {
    const payload: ToastModel = {
      show: false,
    };
    this.store.dispatch({ type: TOGGLE_TOAST, payload });
  }

}
