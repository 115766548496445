import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private api: ApiService, private router: Router) { }

  canActivate(): boolean {        
    
    if ('null' === this.api.isAuthenticated() || this.api.isAuthenticated() == null ) {      
      console.log('false')
      this.router.navigateByUrl('/login');
      return false;
    }
    console.log('true')
    return true;
  }
}
