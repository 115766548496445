import { PlanModel, FilterPlansModel } from './plans.models';
import { Action } from '@ngrx/store';

export const REQUEST_PLANS = 'REQUEST_PLANS',
  REQUEST_PLANS_SUCCESS = 'REQUEST_PLANS_SUCCESS',
  CREATE_PLAN = 'CREATE_PLAN',
  CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS',
  UPDATE_PLAN = 'UPDATE_PLAN',
  UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS',
  DELETE_PLAN = 'DELETE_PLAN',
  DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS',
  FILTER_PLANS = 'FILTER_PLANS';

export class RequestPlans implements Action {
  readonly type = REQUEST_PLANS;
  constructor() {}
}
export class RequestPlansSuccess implements Action {
  readonly type = REQUEST_PLANS_SUCCESS;
  constructor(public payload: PlanModel[]) {}
}

export class CreatePlan implements Action {
  readonly type = CREATE_PLAN;
  constructor(public payload: { body: PlanModel }) {}
}
export class CreatePlanSuccess implements Action {
  readonly type = CREATE_PLAN_SUCCESS;
  constructor(public payload: PlanModel[]) {}
}

export class UpdatePlan implements Action {
  readonly type = UPDATE_PLAN;
  constructor(public payload: PlanModel) {}
}

export class UpdatePlanSuccess implements Action {
  readonly type = UPDATE_PLAN_SUCCESS;
  constructor(public payload: PlanModel) {}
}
export class DeletePlan implements Action {
  readonly type = DELETE_PLAN;
  constructor(public payload: PlanModel) {}
}

export class DeletePlanSuccess implements Action {
  readonly type = DELETE_PLAN_SUCCESS;
  constructor(public payload: PlanModel) {}
}

export class FilterPlans implements Action {
  readonly type = FILTER_PLANS;
  constructor(public payload: FilterPlansModel) {}
}

export type Actions =
  | RequestPlans
  | RequestPlansSuccess
  | CreatePlan
  | CreatePlanSuccess
  | UpdatePlan
  | UpdatePlanSuccess
  | DeletePlan
  | DeletePlanSuccess
  | FilterPlans;
