import { REQUEST_PLANS, FILTER_PLANS } from './../../../plans/store/plans.actions';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Observable } from 'rxjs';
import { PlanModelItem } from '../../../plans/store/plans.models';
import { selectPlans } from '../../../plans/store/plans.reducer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-plans',
  templateUrl: './select-plans.component.html',
  styleUrls: ['./select-plans.component.scss']
})
export class SelectPlansComponent implements OnInit, OnChanges {
  @Output()
  selectChange = new EventEmitter();
  @Input()
  filter: Number;
  plansListObservable: Observable<PlanModelItem[]>;
  plansList: PlanModelItem[];
  selectedPlan = '';
  constructor(private store: Store<AppState>, public translate: TranslateService) {
    this.plansListObservable = this.store.pipe(select(selectPlans));
  }

  ngOnInit() {
    let params = {
      'filter': {
        "order": 'title ASC',
        "where": { "options": { "neq": "" } }
      }
    }
    this.store.dispatch({ type: REQUEST_PLANS, payload: params });
    this.listenPlansListChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && changes.filter.currentValue !== changes.filter.previousValue) {
      this.selectedPlan = '';
    }
  }

  listenPlansListChange() {
    this.plansListObservable.subscribe(plansList => (this.plansList = plansList));
  }

  get filteredPlans() {
    return this.plansList.filter(plan => plan.companyId === this.filter);
  }

  handleChange(event) {
    const planId = event.target.value;
    this.selectedPlan = planId;
    this.selectChange.emit(planId);
  }
}
