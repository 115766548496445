import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Modules */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';

/* Components */
import { UsersComponent } from './users.component';
import { UserFormComponent } from './components/user-form/user-form.component';

/* Providers */
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [{ path: 'users', component: UsersComponent, canActivate: [AuthGuardService] }];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [UsersComponent, UserFormComponent]
})
export class UsersModule {}
