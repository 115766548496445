import { Router } from '@angular/router';
import { DashboardGuard } from './../../../services/dashboard.guard';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() role;
  @Input() clientConfigData;

  routes = [];
  routesAdmin = [
    {
      url: '/users',
      label: 'Users',
      labelFr: 'Users',
      logo: '../../assets/imgs/users-icon.svg'
    },
    {
      url: '/prospects',
      label: 'Comparisons',
      labelFr: 'Comparaisons',
      logo: '../../assets/imgs/prospects-icon.svg'
    },
    {
      url: '/companies',
      label: 'Companies',
      labelFr: 'Entreprises',
      logo: '../../assets/imgs/companies-icon.svg'
    },
    {
      url: '/plans',
      label: 'Plans',
      labelFr: 'Plans',
      logo: '../../assets/imgs/plans-icon.svg'
    },
    {
      url: '/guarantees',
      label: 'Benefits',
      labelFr: 'Garanties',
      logo: '../../assets/imgs/guarantees-icon.svg'
    }
  ];

  routesBroker = [
    {
      url: '/prospects',
      label: 'Comparisons',
      labelFr: 'Comparaisons',
      logo: '../../assets/imgs/prospects-icon.svg'
    },
  ]

  constructor(public translate: TranslateService, private router: Router) {

  }


  ngOnInit() {
    this.routes = this.routesBroker
    if (this.role == 'ADMIN SUPER' || this.role == 'ADMIN STARTER' || this.role == 'ADMIN PRO') {
      this.routes = this.routesAdmin
    }
  }

  goDashboard() {
    DashboardGuard.chooseApp.next(true);
    localStorage.setItem('chooseApp', '1');
    this.router.navigateByUrl('/dashboard')
  }

}
