import {
  REQUEST_GUARANTEES,
  UPDATE_GUARANTEE,
  CREATE_GUARANTEE,
  RESET_GUARANTEES
} from './store/guarantee.actions';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GuaranteeModel } from './store/guarantee.models';
import { selectGuarantees } from './store/guarantee.reducer';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app.state';
import { TOGGLE_TOAST } from './../app.actions';
import { COLUMNS, ACTION_COLUMN } from './guarantees.constants';
import { FILE_CONTAINERS } from '../services/api.service';
import { FILTER_PLANS } from '../plans/store/plans.actions';

@Component({
  selector: 'app-guarantees',
  templateUrl: './guarantees.component.html',
  styleUrls: ['./guarantees.component.scss']
})
export class GuaranteesComponent implements OnInit {
  public guaranteesList: Observable<GuaranteeModel[]>;
  public columns = COLUMNS;
  public actionColumn = ACTION_COLUMN;
  public isFormVisible = false;
  public isEditForm = false;
  public editGuaranteeData: GuaranteeModel = <GuaranteeModel>{};
  public planId: string;
  public guaranteesContainer = FILE_CONTAINERS.guarantees;
  public company: Number = 0;
  public modalId = 'guarantee-form'
  constructor(private store: Store<AppState>) {
    this.guaranteesList = this.store.pipe(select(selectGuarantees));
  }
  ngOnInit() {
    this.store.dispatch({ type: RESET_GUARANTEES });
  }
  private loadGuarantees(planId) {
    this.planId = planId;
    if (planId) {
      this.store.dispatch({ type: REQUEST_GUARANTEES, payload: planId });
    } else {
      this.store.dispatch({ type: RESET_GUARANTEES });
    }
  }
  handleCompanyChange(companyId) {
    this.company = parseInt(companyId, 10);
    this.store.dispatch({ type: RESET_GUARANTEES });
    if (!companyId) {
      this.planId = null;
    }
  }
  handlePlanChange(planId) {
    this.loadGuarantees(planId);
  }
  handleUploadSuccess(event) {
    this.store.dispatch({
      type: TOGGLE_TOAST,
      payload: { show: true, message: 'The CSV has been uploaded successfuly' }
    });
    this.loadGuarantees(this.planId);
  }
  handleFormSubmit(payload) {
    const actionType = this.isEditForm ? UPDATE_GUARANTEE : CREATE_GUARANTEE;
    this.store.dispatch({ type: actionType, payload });
  }
  handleAddButtonClick() {
    this.isEditForm = false;
    this.isFormVisible = true;
    this.editGuaranteeData = <GuaranteeModel>{};
  }
  handleEditButtonClick(guarantee) {
    this.isEditForm = true;
    this.isFormVisible = true;
    this.editGuaranteeData = guarantee;
  }

  get csvUploadParams() {
    return { planId: this.planId };
  }
}
