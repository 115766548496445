import { DashboardGuard } from './../services/dashboard.guard';
import { ApiService } from './../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @Input() clientConfigData:any;
  
  showIndexed = true
  translateService: any;
  currentLang: any;

  URL_REWARDS = environment.externalAppURL.rewards + `?token=${localStorage.getItem('token')}`
  URL_INDIVIDUALS = environment.externalAppURL.individuals + `?_want_to_be=${localStorage.getItem('token')}&_want_to_be_id=${localStorage.getItem('userId')}`

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    translate.addLangs(['EN', 'FR']);
    translate.setDefaultLang('EN');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/EN|FR/) ? browserLang : 'EN');

    this.translateService = translate;
    this.currentLang = translate.getDefaultLang()
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['logout']) {
        this.handleLogout()
      }
    });

    console.log(this.router.url)
    // this.router.navigateByUrl(this.router.url);

  }

  changeLang(lang) {
    this.translate.use(lang)
    this.currentLang = lang
  }

  handleIndexed() {

    this.showIndexed = false;

    let userId = localStorage.getItem('userId');

    this.apiService.getUserRole(userId).subscribe(
      (people: any) => {

        localStorage.setItem('chooseApp', '0');

        DashboardGuard.chooseApp.next(false);
        
        if (people.type.toUpperCase() == 'ADMIN PRO' || people.type.toUpperCase() == 'ADMIN SUPER') {
          this.router.navigateByUrl('/users');
        } else if (people.type.toUpperCase() == 'PROSPECT') {
          this.router.navigateByUrl('/company');
        } else if (people.type.toUpperCase() == 'BROKER') {
          this.router.navigateByUrl('/prospects');
        }

      },
      err => {
        if (err.status === 401) {
          console.log('not logged')
        }
      }
    )

  }

  handleLogout() {
    this.apiService.logout();
  }
}
