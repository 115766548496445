import { GuaranteeModel } from './guarantee.models';
import { AppState } from './../../app.state';
import * as GuaranteesActions from './guarantee.actions';

/* State */
const initialState = {
  list: [],
  error: null,
  filter: null
};

/* Reducers */
export function guaranteesReducer(state = initialState, action) {
  switch (action.type) {
    case GuaranteesActions.REQUEST_GUARANTEES_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    case GuaranteesActions.CREATE_GUARANTEE_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload]
      };
    case GuaranteesActions.UPDATE_GUARANTEE_SUCCESS:
      return {
        ...state,
        list: state.list.map(
          guarantee => (guarantee.id === action.payload.id ? action.payload : guarantee)
        )
      };
    case GuaranteesActions.RESET_GUARANTEES:
      return {
        ...state,
        list: []
      };
    case GuaranteesActions.FILTER_GUARANTEES:
      return {
        ...state,
        filter: action.payload
      };
    default:
      return state;
  }
}

/* Selectors */
export const selectGuarantees = (state: AppState) => state.guarantees.list;

export const selectFilteredGuarantees = (state: AppState) =>
  state.guarantees.filter
    ? state.guarantees.list.filter(
        guarantee => guarantee.insurancePlanId === state.guarantees.filter
      )
    : state.guarantees.list;
