import { TOGGLE_TOAST } from './../app.actions';
import { ApiService } from './../services/api.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { PlanModelItem } from './store/plans.models';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app.state';
import { selectFilteredPlans } from './store/plans.reducer';
import { COLUMNS, ACTION_COLUMN } from './plans.constants';
import {
  CREATE_PLAN,
  UPDATE_PLAN,
  REQUEST_PLANS,
  DELETE_PLAN,
  FILTER_PLANS
} from './store/plans.actions';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
  public plansList: Observable<PlanModelItem[]>;
  public columns = COLUMNS;
  public actionColumn = ACTION_COLUMN;
  public isFormVisible = false;
  public isEditForm = false;
  public ready = false;
  public editPlanData: PlanModelItem = <PlanModelItem>{};
  public company: Number;
  public modalId = 'plan-form'
  public currencies: any;


  constructor(private store: Store<AppState>, private apiService: ApiService) {
    this.plansList = this.store.pipe(select(selectFilteredPlans));
  }

  ngOnInit() {

    this.loadPlans()

    this.apiService.get('/Currencies').subscribe((currencies) => {
      this.currencies = currencies
      this.ready = true
    })
  }

  loadPlans() {
    let params = {
      'filter': {
        'include': ['currency'],
        "order": 'title ASC',
        "where": { "options": { "neq": "" } }
      }
    }
    this.store.dispatch({ type: REQUEST_PLANS, payload: params });
  }

  handleFormSubmit(payload) {
    const actionType = this.isEditForm ? UPDATE_PLAN : CREATE_PLAN;
    this.store.dispatch({ type: actionType, payload });
    this.store.dispatch({
      type: TOGGLE_TOAST,
      payload: { show: true, message: 'Update Success' }
    });
    this.loadPlans()
  }

  handleCompanyChange(company) {
    this.company = company;
    this.store.dispatch({ type: FILTER_PLANS, payload: company });
  }

  handleAddButtonClick() {
    this.isEditForm = false;
    this.isFormVisible = true;

    console.log(this.company);

    this.editPlanData = <PlanModelItem>{
      companyId: this.company
    };
  }

  handleEditButtonClick(data) {
    if (data) {
      this.isEditForm = true;
      this.isFormVisible = true;
      this.editPlanData = data;
    }
  }

  handleDelete(id) {
    this.store.dispatch({ type: DELETE_PLAN, payload: id });
  }
}
