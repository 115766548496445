import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {

  static chooseApp = new BehaviorSubject((localStorage.getItem('chooseApp') == '1'));

  canActivate(): boolean {
    console.log(DashboardGuard.chooseApp.value)
    return DashboardGuard.chooseApp.value;
  }
}
