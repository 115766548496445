import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], key: string, term: string): any {
    if (items && items.length && key && term) {
      return items.filter(item => {
        let value = item[key];
        if (value && typeof value !== 'string') {
          value = value.toString();
        }
        return value && value.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      });
    }
    return items;
  }
}
