import { TOGGLE_TOAST } from './../app.actions';
import { Store } from '@ngrx/store';
import { AppState } from './../app.state';
import { NEEDS } from './../prospects/prospects.constants';
import { COUNTRIES } from './../plans/plans.constants';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ApiService, FILE_CONTAINERS } from './../services/api.service';
import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProspectModel } from '../prospects/store/prospects.models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
})
export class CompanyComponent {

  companyForm: FormGroup;
  company: any;
  prospect: ProspectModel;
  needs = NEEDS;
  countries = COUNTRIES;
  censusFileContainer = FILE_CONTAINERS.prospectsCensus;
  lpFileContainer = FILE_CONTAINERS.prospectsLifeProtection;
  joinersFileContainer = FILE_CONTAINERS.prospectsJoiners;
  successMessage: any;
  savedSuccessMessage: any;
  enableSend = false;

  userId: any;

  aNumberOfEmployes = [
    '3 - 50',
    '51 - 100',
    '101 - 200',
    '201 - 300',
    'Large Group'
  ]

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );


  constructor(private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private apiService: ApiService,
    private router: Router,
    private fb: FormBuilder,
    public translate: TranslateService) {
    // this.initForm()
  }

  handleDownload(fileName: string) {
    const url = this.apiService.downloadStatic(fileName);
    window.open(url);
  }

  ngOnInit() {

    this.userId = localStorage.getItem('userId')

    let params =
    {
      "filter": {
        "where": {
          "createdBy": this.userId
        },
        "order": 'id DESC',
        "limit": 1
      }
    }
    // this.apiService.get('/CorporateProspects', params).subscribe(
    //   (request: any) => {
    //     this.prospect = request[0];
    //     this.initForm()
    //     this.enableSend = true
    //   },
    //   err => {
    //     if (err.status === 401) {
    //       console.error({ err })
    //     }
    //   }
    // );
    this.initForm()
        this.enableSend = true


  }

  initForm() {

    const controls = this.needs.map(c => new FormControl(false));

    if (this.prospect) {
      const needsString = this.prospect.needs.split(',');

      for (const index in this.needs) {
        const need = this.needs[index];
        if (needsString.indexOf(need.value) > -1) {
          controls[index].setValue(true);
        }
      }
    }

    this.companyForm = this.fb.group({
      company: [this.prospect ? this.prospect.company : '', Validators.required],
      firstName: [this.prospect ? this.prospect.firstName : '', Validators.required],
      lastName: [this.prospect ? this.prospect.lastName : '', Validators.required],
      email: [this.prospect ? this.prospect.email : '', [Validators.required, Validators.email]],
      jobTitle: [this.prospect ? this.prospect.jobTitle : ''],
      countryCode: [this.prospect ? this.prospect.countryCode : ''],
      phoneNumber: [this.prospect ? this.prospect.phoneNumber : ''],
      skypeId: [this.prospect ? this.prospect.skypeId : ''],
      address: [this.prospect ? this.prospect.address : '', Validators.required],
      needs: new FormArray(controls, Validators.required),
      headquartersCountry: [this.prospect ? this.prospect.headquartersCountry : ''],
      contractStartDate: [this.prospect ? this.prospect.contractStartDate : ''],
      currentGroupRenewal: [this.prospect ? this.prospect.currentGroupRenewal : ''],
      numberOfEmployess: [this.prospect ? this.prospect.numberOfEmployess : ''],
      numberOfDependents: [this.prospect ? this.prospect.numberOfDependents : ''],
      census: [this.prospect ? this.prospect.census : ''],
      lifeProtection: [this.prospect ? this.prospect.lifeProtection : ''],
      joiners: [this.prospect ? this.prospect.joiners : ''],
      agree: ['', Validators.required]      
    }
    );

  }


  handleUploadSuccess(fileName: string, field: string) {
    this.companyForm.get(field).setValue(fileName);
  }

  get censusFileName() {
    return this.companyForm && this.companyForm.get('census').value;
  }
  get lpFileName() {
    return this.companyForm && this.companyForm.get('lifeProtection').value;
  }

  get joinersFileName() {
    return this.companyForm && this.companyForm.get('joiners').value;
  }

  handleSubmit() {

    const selectedNeedsValues = this.companyForm.value.needs
      .map((v, i) => (v ? this.needs[i].value : null))
      .filter(v => v !== null);

    let needs = selectedNeedsValues.join(',');

    let body = {
      "temporary": true,
      "firstName": this.companyForm.value.firstName,
      "lastName": this.companyForm.value.lastName,
      "email": this.companyForm.value.email,
      "company": this.companyForm.value.company,
      "jobTitle": this.companyForm.value.jobTitle,
      "phoneNumber": this.companyForm.value.phoneNumber,
      "address": this.companyForm.value.address,
      "needs": needs,
      "headquartersCountry": this.companyForm.value.headquartersCountry,
      "numberOfEmployess": this.companyForm.value.numberOfEmployess,
      "numberOfDependents": this.companyForm.value.numberOfDependents,
      "census": this.companyForm.value.census,
      "lifeProtection": this.companyForm.value.lifeProtection,
      "countryCode": this.companyForm.value.lifeProtection,
      "skypeId": this.companyForm.value.skypeId,
      "contractStartDate": this.companyForm.value.contractStartDate,
      "currentGroupRenewal": this.companyForm.value.currentGroupRenewal,
      "companyAddress": this.companyForm.value.companyAddress,
      "createdBy": this.userId,
      "modifiedBy": this.userId,
      "fileCensus": this.companyForm.value.census,
      "fileProtection": this.companyForm.value.lifeProtection,
      "joiners": this.companyForm.value.joiners,
    }

    if (this.prospect) {
      this.apiService.put('/Prospects', this.prospect.id.toString(), body).subscribe(
        (newProspect: any) => {
          console.log({ newProspect })
          let msg = this.translate.currentLang =='EN' ? 'Updated successfuly' : 'Mis à jour avec succés'
          this.store.dispatch({
            type: TOGGLE_TOAST,
            payload: { show: true, message: msg }
          });
          this.enableSend = true
        },
        err => {
          if (err.status === 401) {
            console.error('Unauthorized')
            console.error({ err })
          } else {
            console.error({ err })
          }
          this.enableSend = false
        }
      );
    } else {
      this.apiService.post('/CorporateProspects', body).subscribe(
        (newProspect: any) => {
          console.log({ newProspect })


          let msg = this.translate.currentLang =='EN' ? 'Saved successfuly' : 'Enregistré avec succès'

          this.store.dispatch({
            type: TOGGLE_TOAST,
            payload: { show: true, message: msg }
          });

          this.prospect = newProspect

          this.enableSend = true
        },
        err => {
          if (err.status === 401) {
            console.error('Unauthorized')
            console.error({ err })
          } else {
            console.error({ err })
          }
          this.enableSend = false
        }
      );
    }


  }

  sendInfo() {
    if (this.companyForm.valid) {
      if (this.prospect) {
        this.apiService.patch('/CorporateProspects', this.prospect.id.toString(), { temporary: false }).subscribe(
          (sendedProspect: any) => {
            console.log({ sendedProspect })

            this.successMessage = this.translate.currentLang =='EN' ? 'Your request is being processed, you will receive via mail, the selected plans' : 'Votre demande est en cours de traitement, vous recevrez par courrier les plans sélectionnés'

          },
          err => {
            if (err.status === 401) {
              console.error('Unauthorized')
              console.error({ err })
            } else {
              console.error({ err })
            }
          }
        );
      }
    }
  }

}
