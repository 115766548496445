import { ProspectModel } from './prospects.models';
import { AppState } from './../../app.state';
import * as ProspectsActions from './prospects.actions';

/* State */
const initialState = {
  list: [],
  error: null
};

/* Reducers */
export function prospectsReducer(state = initialState, action) {
  switch (action.type) {
    case ProspectsActions.REQUEST_PROSPECTS_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    case ProspectsActions.CREATE_PROSPECT_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload]
      };
    case ProspectsActions.UPDATE_PROSPECT_SUCCESS:
      return {
        ...state,
        list: state.list.map(prospect => (prospect.id === action.payload.id ? action.payload : prospect))
      };
    case ProspectsActions.DELETE_PROSPECT_SUCCESS:
      return {
        ...state,
        list: state.list.filter(prospect => prospect.id !== action.payload)
      };
    default:  
      return state;
  }
}


/* Selectors */
export const selectProspects = (state: AppState) => state.prospects.list;
