export const COLUMNS = [
  {
    label: 'First Name',
    labelFr: 'Prénom',
    dataKey: 'firstName'
  },
  {
    label: 'Last Name',
    labelFr: 'Nom',
    dataKey: 'lastName',
    className: 'column-lastname'
  },
  {
    label: 'Email',
    labelFr: 'Email',
    dataKey: 'email'
  },
  {
    label: 'Company',
    labelFr: 'Entreprise',
    dataKey: 'company'
  }
];

export const ACTION_COLUMN = {
  label: 'Quotes',
  labelFr: 'Comparaisons',
  buttonLabel: 'Edit',
  buttonLabelFr: 'Modifier',
  buttonIcon: 'fa fa-edit',
  // actionButtons: [{ 'label': 'Compare', 'labelFr': 'Comparer', 'action': 'compare' }]
  actionIcons: [
    { 'icon': 'fa fa-clipboard', 'action': 'quotes', 'modal': true, 'modalDataTogle': 'modal', 'modalDataTarget': 'quotes-modal' },
    { 'icon': 'fa fa-usd', 'action': 'compare' },
    { 'icon': 'fa fa-trash', 'action': 'delete' }
  ]
};


export const NEEDS = [
  { value: 'inpatientBenefits', label: 'In-patient benefits (including assitance)', labelFr: 'Hospitalisation' },
  { value: 'outpationBenefits', label: 'Out-patient benefits', labelFr: 'Services consultations externes' },
  { value: 'preventiveCare', label: 'Preventive care', labelFr: 'Prévention' },
  { value: 'maternityCare', label: 'Maternity care', labelFr: 'Maternité' },
  { value: 'dentalVisionCare', label: 'Dental/Vision care', labelFr: 'Soins dentaires / optique' },
  { value: 'lifeIncomeProtection', label: 'Life/Income protection', labelFr: 'Assurance vie / revenus' },
];
