import { COUNTRIES } from './../../../plans/plans.constants';
import { FILE_CONTAINERS, ApiService } from './../../../services/api.service';
import { NEEDS } from './../../prospects.constants';
import { AppState } from './../../../app.state';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { ProspectModel } from './../../store/prospects.models';

import { pickBy } from 'lodash';
import { identity } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prospect-form',
  templateUrl: './prospect-form.component.html',
  styleUrls: ['./prospect-form.component.scss']
})
export class ProspectFormComponent implements OnChanges {
  @Input()
  isEditProspect = false;
  @Input()
  visible: boolean;
  @Input()
  modalId;
  @Input()
  prospect: ProspectModel;
  @Output()
  visibleChange = new EventEmitter();
  @Output()
  formSubmit = new EventEmitter();
  @Output()
  deleteEntity = new EventEmitter();
  prospectForm: FormGroup;
  censusFileContainer = FILE_CONTAINERS.prospectsCensus;
  lpFileContainer = FILE_CONTAINERS.prospectsLifeProtection;
  joinersFileContainer = FILE_CONTAINERS.prospectsJoiners;
  needs = NEEDS;
  countries = COUNTRIES;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.prospect && changes.prospect.currentValue !== changes.prospect.previousValue) {
      this.initializeForm();
    }
  }

  initializeForm() {
    // Create a new array with a form control for each order
    const controls = this.needs.map(c => new FormControl(false));

    if (this.prospect.id) {
      const needsString = this.prospect.needs.split(',');

      for (const index in this.needs) {
        const need = this.needs[index];
        if (needsString.indexOf(need.value) > -1) {
          controls[index].setValue(true);
        }
      }
    }

    const dataForm = {
      company: [this.prospect.company || '', Validators.required],
      firstName: [this.prospect.firstName || '', Validators.required],
      lastName: [this.prospect.lastName || ''],
      email: [this.prospect.email || '', [Validators.required, Validators.email]],
      jobTitle: [this.prospect.jobTitle || ''],
      skypeId: [this.prospect.skypeId || ''],
      phoneNumber: [this.prospect.phoneNumber || ''],
      address: [this.prospect.address || ''],
      needs: new FormArray(controls, Validators.required),
      headquartersCountry: [this.prospect.headquartersCountry || ''],
      numberOfEmployess: [this.prospect.numberOfEmployess || ''],
      numberOfDependents: [this.prospect.numberOfDependents || ''],
      census: [this.prospect.census || ''],
      lifeProtection: [this.prospect.lifeProtection || ''],
      joiners: [this.prospect.joiners || '']
    };

    this.prospectForm = this.fb.group(dataForm);
  }

  handleSubmit() {
    if (this.prospectForm.valid) {
      const prospectData = pickBy(
        {
          ...this.prospectForm.value,
          id: this.prospect ? this.prospect.id : ''
        },
        identity
      );

      const selectedNeedsValues = this.prospectForm.value.needs
        .map((v, i) => (v ? this.needs[i].value : null))
        .filter(v => v !== null);

      prospectData.needs = selectedNeedsValues.join(',');

      prospectData.temporary = false;

      this.formSubmit.emit(prospectData);

      this.closeForm();
    }
  }

  handleDelete() {
    this.deleteEntity.emit(this.prospect.id);
    this.closeForm();
  }

  handleUploadSuccess(fileName: string, field: string) {
    this.prospectForm.get(field).setValue(fileName);
  }

  handleDownload(fileName: string) {    
    const url = this.api.downloadStatic(fileName);
    window.open(url);
  }

  closeForm() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  get censusFileName() {
    return this.prospectForm && this.prospectForm.get('census').value;
  }
  get lpFileName() {
    return this.prospectForm && this.prospectForm.get('lifeProtection').value;
  }
  get joinersFileName() {
    return this.prospectForm && this.prospectForm.get('joiners').value;
  }

  get submitButtonText() {
    return this.isEditProspect ? 'Save changes' : 'Add prospect';
  }

  get titleText() {
    return this.isEditProspect ? this.translate.instant('COMPARISONS.edit_prospect'): this.translate.instant('COMPARISONS.new_prospect');
  }
}
