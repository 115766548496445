import { DashboardGuard } from './../../../services/dashboard.guard';
import { Router } from '@angular/router';
import { ApiService } from './../../../services/api.service';
import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-login-front',
  templateUrl: './login-front.component.html',
  styleUrls: ['./login-front.component.css'],
})
export class LoginFrontComponent {

  userForm: FormGroup;
  loginForm: FormGroup;
  user: any;

  errorMessage: any;
  loading = false;
  authFailed = false;
  showPassword = false
  _want_to_be;
  _want_to_be_id;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private api: ApiService,
    private router: Router,
    private fb: FormBuilder,
    public translate: TranslateService,
    private route: ActivatedRoute) {

    this._want_to_be = this.route.snapshot.queryParamMap.get("_want_to_be")
    this._want_to_be_id = this.route.snapshot.queryParamMap.get("_want_to_be_id")

    localStorage.setItem('chooseApp', '0');
    DashboardGuard.chooseApp.next(false);

    if (this._want_to_be && this._want_to_be_id) {
      let user = {
        id: this._want_to_be,
        userId: this._want_to_be_id
      }
      this.initSesion(user)
    }

    this.initializeLoginForm()
    this.initializeSingupForm()

  }

  displayPassword() {
    this.showPassword = !this.showPassword
  }

  initializeLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  initializeSingupForm() {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      agree: ['', Validators.required],
      password: ['', Validators.required]
    });
  }


  handleLogin() {
    if (this.loginForm.valid) {
      const { email, password } = this.loginForm.value;
      this.authFailed = false;
      this.login(email, password)
    }
  }

  login(email, password) {
    this.loading = true;
    this.api.login(email, password).subscribe(
      (user: any) => {

        this.initSesion(user)

      },
      err => {
        this.loading = false;
        if (err.status === 401) {
          this.authFailed = true;
        }
      }
    );
  }

  initSesion(user) {
    this.api.initSession(user.id);

    this.api.getUserRole(user.userId).subscribe(
      (people: any) => {
        // TODO check emailVerify

        localStorage.setItem('userId', user.userId);
        localStorage.setItem('userRole', people.type.toUpperCase())

        if (people.type.toUpperCase() == 'ADMIN PRO' ||
          people.type.toUpperCase() == 'ADMIN SUPER'
          || people.type.toUpperCase() == 'BROKER') {

          localStorage.setItem('chooseApp', '1');

          DashboardGuard.chooseApp.next(true);
          this.router.navigateByUrl('/dashboard');

        } else if (people.type.toUpperCase() == 'PROSPECT') {
          this.router.navigateByUrl('/company');
        }
      },
      err => {
        if (err.status === 401) {
          this.authFailed = true;
          this.api.logout();
        }
      }
    )
  }


  handleSignup() {
    if (this.userForm.valid) {

      this.loading = true

      let body = {
        firstName: this.userForm.value.firstName,
        lastName: this.userForm.value.lastName,
        email: this.userForm.value.email,
        password: this.userForm.value.password,
        lang: this.translate.currentLang
      }

      this.api.post('/People/Prospects', body).subscribe(
        (newUser: any) => {
          console.log({ newUser })
          // this.login(this.userForm.value.email, this.userForm.value.password, true)
          this.login(this.userForm.value.email, this.userForm.value.password)
          this.loading = false
        },
        err => {
          if (err.status === 401) {
            this.authFailed = true;
          }
          if (err.status === 422) {
            console.log(err.error.error.message)
            this.errorMessage = err.error.error.message
          }
          this.loading = false
        }
      );
    }
  }

}
