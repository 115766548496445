import { element } from 'protractor';
import { TOGGLE_TOAST } from './../app.actions';
import { AppState } from './../app.state';
import { Store } from '@ngrx/store';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-breakdown',
  templateUrl: './breakdown.component.html',
  styleUrls: ['./breakdown.component.css'],
})

export class BreakdownComponent {

  public Editor = DecoupledEditor;
  
  editorData = "<p></p>"
  editorContent = ""

  public onReady( editor ) {
      editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );
  }

  public onChange( { editor }: ChangeEvent ) {
    this.editorContent = editor.getData();
  }

  @ViewChild('closeModal') closeModal;

  companies = [];
  companiesAux = [];
  companySelected: any;
  loadingSaveFiles = false;
  comparisonId: any;
  corporateProspectId: any;
  comparison: any;
  enableDownloadButton = true;
  remarkFr: string;
  remarkEn: string;
  comparisonCompanyContainer = 'comparisons'
  customOptions = [{
    import: 'formats/font',
    whitelist: ['sanz-serif', 'monospace', 'poppins', 'arial']
  }]

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private api: ApiService,
    private router: Router,
    private store: Store<AppState>,
    private route: ActivatedRoute) { }


  ngOnInit() {
    this.comparisonId = this.route.snapshot.params['id']

    this.api.get(`/Comparisons/${this.comparisonId}`).subscribe(
      (comparison: any) => {

        this.comparison = comparison

        // to back button
        this.corporateProspectId = comparison.corporateProspectId

        if (!this.comparison.data.summary) {
          this.comparison.data.summary = []
          this.comparison.data.summary.push({
            'quotationFor': '',
            'companyDetails': '',
            'date': '',
            'contractStartDate': '',
            'intermediary': '',
            'currentGroupRenewal': ''
          })
        }

        if (!this.comparison.data.insurance) {
          this.comparison.data.insurance = []
          this.comparison.data.insurance.push({
            'optionName': '',
            'underWrittingBasis': '',
            'inDay': '',
            'outPatient': '',
            'paymentMethod': '',
            'premiumPayment': '',
            'optionOne': '',
            'optionTwo': ''
          })
        }

        if (!this.comparison.data.remarks) {
          this.comparison.data.remarks = []
          this.comparison.data.remarks.push({
            'content': ''
          })
        }

        // btn modal attach insurance quotes
        this.companies = this.comparison.data.companies

      },
      (err) => {
        console.error(err)
      })
  }

  save() {
    console.log("editorData",this.editorContent)

    this.comparison.data.companies = this.companies
    this.comparison.data.remarks = [{
      currentGroupRenewal: this.editorContent
    }];

    this.api.put('/Comparisons', this.comparisonId, this.comparison).subscribe(
      (comparison: any) => {
        this.store.dispatch({
          type: TOGGLE_TOAST,
          payload: { show: true, message: 'Saved successfuly' }
        });
        this.enableDownloadButton = false
      }
      , (err) => {
        console.error(err)
      })
  }

  sendMail() {
    this.router.navigateByUrl('/compose-mail/' + this.comparisonId);
  }

  downloadPDF() {
    window.open(this.api.getDownloadUrl(`/Comparisons/${this.comparisonId}/download`))
  }


  onBlur_RequestProposals($event) {

  }

  onEditorRemarksCreated($event) {

  }

  openCompaniesFilesModal() {
    this.companySelected = undefined
    console.log(this.companies)
    this.companiesAux = JSON.parse(JSON.stringify(this.companies))
  }

  onSelectCompany(event) {
    let id = event.target.value
    for (let company of this.companiesAux) {
      if (id == company.id) {
        this.companySelected = company
      }
    }
  }

  handleUploadSuccess(fileName: string, index) {

    this.companySelected.files[index].name = fileName;
  }

  addCompanyFile() {
    this.companySelected.files.push({})
  }

  removeCompanyFile(index) {
    this.companySelected.files.splice(index, 1);
  }

  cleanFilesBefereSave() {

    for (let indexCompany in this.companiesAux) {
      let company = this.companiesAux[indexCompany]
      this.companiesAux[indexCompany].files = company.files.filter((file) => {

        if (!file.name) {
          return false
        }

        return true

      })
    }
  }

  saveCompanyFiles() {

    this.cleanFilesBefereSave()

    this.loadingSaveFiles = true

    this.api.get(`/Comparisons/${this.comparisonId}`).subscribe(
      (comparison: any) => {

        comparison.data.companies = JSON.parse(JSON.stringify(this.companiesAux))

        this.api.put('/Comparisons', this.comparisonId, comparison).subscribe(
          (comparison: any) => {
            this.companies = JSON.parse(JSON.stringify(this.companiesAux))
            this.loadingSaveFiles = false

            this.closeModal.nativeElement.click()
          }
          , (err) => {
            console.error(err)
            this.loadingSaveFiles = false
          })
      }, err => {
        this.loadingSaveFiles = false
      })

  }

}
