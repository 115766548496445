import { InterceptorService } from './services/interceptor.service';
import { DashboardGuard } from './services/dashboard.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MailModule } from './mail/mail.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FootComponent } from './frontend/foot/foot.component';
/* Frontend Components */
import { ResetComponent } from './frontend/auth/reset/reset.component';
import { ForgotComponent } from './frontend/auth/forgot/forgot.component';
import { LoginFrontComponent } from './frontend/auth/login/login-front.component';

import { AuthGuardService } from './services/auth-guard.service';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* Routes Modules */
import { SharedModule } from './shared/shared.module';
import { UsersModule } from './users/users.module';
import { ProspectsModule } from './prospects/prospects.module';
import { CompaniesModule } from './companies/companies.module';
import { PlansModule } from './plans/plans.module';
import { GuaranteesModule } from './guarantees/guarantees.module';
import { BreakdownModule } from './breakdown/breakdown.module';
import { CompanyModule } from './company/company.module';
import { CompareModule } from './compare/compare.module';

/* Components */
import { AppComponent } from './app.component';

/* Providers */
import { ApiService } from './services/api.service';
import { ErrorHandlerService } from './services/error-handler.service';

/* Reducers */
import { appReducer } from './app.reducer';
import { companiesReducer } from './companies/store/companies.reducer';
import { plansReducer } from './plans/store/plans.reducer';
import { prospectsReducer } from './prospects/store/prospects.reducer';
import { usersReducer } from './users/store/users.reducer';
import { guaranteesReducer } from './guarantees/store/guarantee.reducer';

/* Effects */
import { UsersEffects } from './users/store/users.effects';
import { PlansEffects } from './plans/store/plans.effects';
import { CompaniesEffects } from './companies/store/companies.effects';
import { ProspectsEffects } from './prospects/store/prospects.effects';
import { GuaranteesEffects } from './guarantees/store/guarantee.effects';

import { SiteComponent } from './site/site.component';
import { AdminComponent } from './admin/admin.component';
import { CompanyDocumentationComponent } from './company-documentation/company-documentation.component';

const frontendRoutes: Routes = [
  { path: '', component: LoginFrontComponent },
  { path: 'login', component: LoginFrontComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'forgot-password', component: ResetComponent },
  { path: 'company-documentation/:companyId', component: CompanyDocumentationComponent },
  { path: 'company-documentation', component: CompanyDocumentationComponent },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService, DashboardGuard]
  }
];



export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    FootComponent,
    LoginFrontComponent,
    ForgotComponent,
    ResetComponent,
    SiteComponent,
    AdminComponent,
    CompanyDocumentationComponent,
    DashboardComponent    
  ],
  imports: [

    RouterModule.forRoot(
      frontendRoutes,
      //      { enableTracing: false } // <-- debugging purposes only
    ),

    StoreModule.forRoot({
      app: appReducer,
      users: usersReducer,
      prospects: prospectsReducer,
      companies: companiesReducer,
      plans: plansReducer,
      guarantees: guaranteesReducer,
    }),
    EffectsModule.forRoot([
      UsersEffects,
      PlansEffects,
      CompaniesEffects,
      ProspectsEffects,
      GuaranteesEffects,
    ]),
    BrowserModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    UsersModule,
    ProspectsModule,
    CompaniesModule,
    PlansModule,
    GuaranteesModule,
    CompareModule,
    CompanyModule,
    BreakdownModule,
    MailModule,
    CKEditorModule
  ],
  providers: [
    ApiService,
    AuthGuardService,
    ErrorHandlerService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
