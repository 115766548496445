import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'; // add this 1 of 4

@Pipe({
  name: 'moment'
})
export class MomentPipePipe implements PipeTransform {

  transform(value: any, format?: any, dateParam?: any): any {

    let date = moment(value)
    let now = moment()
    let r: any
    let duration: any

    switch (format) {
      case 'diff_now_days':
        duration = moment.duration(date.diff(now));
        r = parseInt(duration.asDays())
        break
      case 'diff_now_hours':

        duration = moment.duration(date.diff(now));
        r = `${duration.hours()}:${("0" + duration.minutes()).slice(-2)}`
        break
      default:
          r = date.format(format)
          break
    }

    return r;
  }

}
