
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Modules */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';

/* Components */
import { CompareComponent } from './compare.component';

/* Providers */
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [{ path: 'compare/:prospectId', component: CompareComponent, canActivate: [AuthGuardService] }];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [CompareComponent]
})
export class CompareModule {}
