import { identity } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { GuaranteeModel } from '../../store/guarantee.models';
import { pickBy } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-guarantee-form',
  templateUrl: './guarantee-form.component.html',
  styleUrls: ['./guarantee-form.component.scss']
})
export class GuaranteeFormComponent implements OnChanges {
  @Input() guarantee: GuaranteeModel = <GuaranteeModel>{};
  @Input() isEdit = false;
  @Input() visible: boolean;
  @Input() modalId;
  @Input() planId: string;
  @Output() visibleChange = new EventEmitter();
  @Output() formSubmit = new EventEmitter();
  guaranteeForm: FormGroup;
  constructor(private fb: FormBuilder, private translate: TranslateService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.guarantee && changes.guarantee.currentValue !== changes.guarantee.previousValue) {
      this.initializeForm();
    }
  }

  initializeForm() {

    this.guaranteeForm = this.fb.group({
      guaranteeId: [this.guarantee.guaranteeId],
      enTitle: [this.guarantee.guarantee ? this.guarantee.guarantee['enTitle'] : '', Validators.required],
      enDetail: [this.guarantee.enDetail, Validators.required],
      frDetail: [this.guarantee.frDetail],
      frTitle: [this.guarantee.guarantee ? this.guarantee.guarantee['frTitle'] : '', Validators.required],
      colour: [this.guarantee.colour],
    });
  }

  handleSubmit() {
    if (this.guaranteeForm.valid) {
      const guaranteeData = pickBy(
        {
          ...this.guaranteeForm.value,
          id: this.guarantee.id,
          insurancePlanId: this.planId,
        },
        identity
      );
      this.formSubmit.emit(guaranteeData);
      this.closeForm();
    }
  }

  closeForm() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  get submitButtonText() {
    return this.isEdit ? this.translate.instant('GUARANTEES.edit_guarantee') : this.translate.instant('GUARANTEES.add_guarantee');
  }

  get titleText() {
    return this.isEdit ? this.translate.instant('GUARANTEES.edit_guarantee') : this.translate.instant('GUARANTEES.add_guarantee');
  }

}
