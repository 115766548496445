import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
// import { ErrorHandlerService } from './services/error-handler.service';
import { Component, Inject } from '@angular/core';
// import { Store, select } from '@ngrx/store';
// import { AppState } from './app.state';
// import { Observable } from 'rxjs';
import { ApiService } from './services/api.service';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Meta, DOCUMENT, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  clientConfigData: any;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private apiService: ApiService,
    private router: Router,
    private api: ApiService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private _document: any,
    private titleService: Title
  ) {

    translate.addLangs(['EN', 'FR']);
    translate.setDefaultLang('EN');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/EN|FR/) ? browserLang : 'EN');

    if (this._document.documentElement.lang == 'fr') {
      translate.use('FR');
    }

  }

  ngOnInit() {

    //client config

    this.apiService.getConfigData().subscribe(
      (clientConfigData: any) => {
        this.clientConfigData = clientConfigData.response
        this.titleService.setTitle(this.clientConfigData.name)
        this.favIcon.href = this.clientConfigData.logo;
      })

    let userId = localStorage.getItem('userId');

    if (!userId) {
      return true;
    } else {
      console.log('userId', userId)
      this.api.getUserRole(userId).subscribe(
        (people: any) => {
          if (localStorage.getItem('chooseApp') == '0') {
            if (people.type.toUpperCase() == 'ADMIN PRO' || people.type.toUpperCase() == 'ADMIN SUPER') {
              if (this.router.isActive('/', true)) {
                this.router.navigateByUrl('/users');
              }
            } else if (people.type.toUpperCase() == 'PROSPECT') {
              this.router.navigateByUrl('/company');
            }
          } else {

            const tree: UrlTree = this.router.parseUrl(this.router.url);
            const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
            if (g){
              let s: UrlSegment[] = g.segments;
              if (s[0].path == 'company-documentation') {
                localStorage.setItem('chooseApp', '1');
                this.router.navigateByUrl(this.router.url);
              } else {
                this.router.navigateByUrl('/dashboard');
              }
            }else{
              this.router.navigateByUrl('/dashboard');
            }

            // console.log(s[0].path);

            
          }

        },
        err => {
          if (err.status === 401) {
            console.log('not logged')
          }
        }
      )
    }

  }

  get isAuthenticated(): boolean {
    return this.apiService.isAuthenticated() ? true : false;
  }

  get isCustomView(): boolean {
    return this.router.isActive('/company-documentation', false)
  }
}
