import { PLAN_OPTIONS } from './../plans.constants';
import { createSelector } from '@ngrx/store';
import { AppState } from './../../app.state';
import * as PlansActions from './plans.actions';

/* State */
const initialState = {
  list: [],
  filter: null
};

/* Reducers */
export function plansReducer(state = initialState, action) {
  switch (action.type) {
    case PlansActions.REQUEST_PLANS_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    case PlansActions.CREATE_PLAN_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload]
      };
    case PlansActions.UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        list: state.list.map(plan => (plan.id === action.payload.id ? action.payload : plan))
      };
    case PlansActions.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        list: state.list.filter(prospect => prospect.id !== action.payload)
      };
    case PlansActions.FILTER_PLANS:
      return {
        ...state,
        filer: action.payload
      };
    default:
      return state;
  }
}

/* Selectors */
const selectFilter = (state: AppState) => {
  return state.plans.filter;
};

export const selectPlans = (state: AppState) => {
  return state.plans.list.map(plan => {
    if (!plan.options){
      return {
        ...plan,
        PLAN_OPTIONS
      };
    }
    let options: any[] = plan.options.split(',').map(option => option.trim());

    options = options.map(it => it.toUpperCase())

    options = PLAN_OPTIONS.filter(plan => options.includes(plan.label.toUpperCase()));

    return {
      ...plan,
      options
    };
  });
};

export const selectFilteredPlans = createSelector(selectPlans, selectFilter, (plans, filter) => {
  return filter ? plans.filter(plan => plan.companyId === filter) : plans;
});
