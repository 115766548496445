import { ComparisonQuotesComponent } from './components/comparison-quotes/comparison-quotes.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Modules */
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';

/* Components */
import { ProspectsComponent } from './prospects.component';
import { ProspectFormComponent } from './components/prospect-form/prospect-form.component';

/* Providers */
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [
  { path: 'prospects', component: ProspectsComponent, canActivate: [AuthGuardService] },
];


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    ProspectsComponent,
    ProspectFormComponent,
    ComparisonQuotesComponent
  ]
})
export class ProspectsModule { }
