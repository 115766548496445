import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from './../../../services/api.service';
import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css'],
})
export class ResetComponent {

  resetForm: FormGroup;
  authFailed = false;
  sendSuccess = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, private api: ApiService,
    private router: Router,
    private fb: FormBuilder,
    public translate: TranslateService) {
    this.initializeResetForm()
  }


  initializeResetForm() {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  handleSubmit() {
    if (this.resetForm.valid) {

      let body = {
        email: this.resetForm.value.email,
        lang: this.translate.currentLang
      }

      this.api.post('/People/reset', body).subscribe(
        (res: any) => {
          this.sendSuccess = true;
        },
        err => {
          if (err.status === 401) {
            this.authFailed = true;
          }
        }
      );
    }
  }

}
