import { NgModule } from '@angular/core';

/* Modules */
import { SharedModule } from './../shared/shared.module';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Components */
import { PlanFormComponent } from './components/plan-form/plan-form.component';
import { PlansComponent } from './plans.component';

/* Providers */
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [
  { path: 'plans', component: PlansComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [PlansComponent, PlanFormComponent]
})
export class PlansModule { }
