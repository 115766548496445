import { CompanyModel } from './companies.models';
import { AppState } from './../../app.state';
import * as CompaniesActions from './companies.actions';

/* State */
const initialState = {
  list: [],
  error: null
};

/* Reducers */
export function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case CompaniesActions.REQUEST_COMPANIES_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    case CompaniesActions.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload]
      };
    case CompaniesActions.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        list: state.list.map(company => (company.id === action.payload.id ? action.payload : company))
      };
    case CompaniesActions.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        list: state.list.filter(company => company.id !== action.payload)
      };
    default:
      return state;
  }
}

/* Selectors */
export const selectCompanies = (state: AppState) => state.companies.list;
