import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { QuillModule } from 'ngx-quill';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Modules */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

/* Components */
import { MailComponent } from './mail.component';

/* Providers */
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [{ path: 'compose-mail/:comparisonId', component: MailComponent, canActivate: [AuthGuardService] }];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MailComponent]
})
export class MailModule {}
