import { Store } from '@ngrx/store';
import { AppState } from './../app.state';
import { TOGGLE_TOAST } from './../app.actions';
import { ApiService } from './../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.css'],
})

export class MailComponent {

  comparisonId: any;
  comparison: any;
  mailForm: FormGroup;
  attachMailContainer = 'tmpEmail'
  filesToSend = []
  customOptions = [{
    import: 'formats/font',
    whitelist: ['sanz-serif', 'monospace', 'poppins', 'arial']
  }]

  public Editor = DecoupledEditor;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private store: Store<AppState>,
  ) {
    this.initMailForm()

  }

  ngOnInit() {
    this.comparisonId = this.route.snapshot.params['comparisonId'];

    this.api.get(`/Comparisons/${this.comparisonId}`).subscribe(
      (comparison: any) => {

        this.comparison = comparison

      },
      (err) => {
        console.error(err)
      })
  }


  onEditorReady(editor): void {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  
  initMailForm() {
    this.mailForm = this.fb.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
      cc: [''],
      bcc: [''],
      message: [''],
      attachments: [[]]
    });
  }

  sendMail() {
    if (this.mailForm.valid) {

      this.mailForm.controls['attachments'].setValue(this.filesToSend)

      this.api.post(`/Comparisons/${this.comparisonId}/email`, this.mailForm.value).subscribe(
        (comparison: any) => {
          console.log(comparison)

          this.store.dispatch({
            type: TOGGLE_TOAST,
            payload: { show: true, message: 'Mail Sended successfuly' }
          });

          this.router.navigateByUrl('/breakdown/' + this.comparisonId);

        }
        , (err) => {
          console.error(err)
          this.store.dispatch({
            type: TOGGLE_TOAST,
            payload: { show: true, message: err.error.error.name + ': ' + err.error.error.message }
          });
        })
    }
  }

  onEditorRemarksChanged($event) {

    if ($event.html.length > 0) {

      this.mailForm.controls['message'].setValue($event.html)

    }

  }

  onEditorRemarksCreated($event) {

  }

  handleUploadSuccess(fileName: string) {

    // this.mailForm.files[index].name = fileName;

    this.filesToSend.push(fileName)

  }

  removeFile(file) {
    this.api.deleteFile(this.attachMailContainer, file).subscribe(
      (deletedFile: any) => {
        console.log(deletedFile)
        console.log(this.filesToSend)
        let indexToRemove = this.filesToSend.indexOf(file);
        if (indexToRemove > -1) {
          this.filesToSend.splice(indexToRemove, 1);
        }
      }
      , (err) => {
        console.error(err)
        this.store.dispatch({
          type: TOGGLE_TOAST,
          payload: { show: true, message: err.error.error.name + ': ' + err.error.error.message }
        });
      })

  }

}
