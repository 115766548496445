import { ErrorHandlerService } from './../../services/error-handler.service';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ENDPOINTS } from './../../services/api.service';
import { ApiService } from '../../services/api.service';
import {
  REQUEST_COMPANIES,
  REQUEST_COMPANIES_SUCCESS,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS
} from './companies.actions';
import { RAISE_API_ERROR } from '../../app.actions';

@Injectable()
export class CompaniesEffects {
  @Effect()
  requestCompanies: Observable<Action> = this.actions.pipe(
    ofType(REQUEST_COMPANIES),
    mergeMap((action: any) =>
      this.api.get(ENDPOINTS.companies, action.payload ).pipe(
        map(payload => ({ type: REQUEST_COMPANIES_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  createCompany: Observable<Action> = this.actions.pipe(
    ofType(CREATE_COMPANY),
    mergeMap((action: any) =>
      this.api.post(ENDPOINTS.companies, action.payload).pipe(
        map(payload => ({ type: CREATE_COMPANY_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  updateCompany: Observable<Action> = this.actions.pipe(
    ofType(UPDATE_COMPANY),
    mergeMap((action: any) =>
      this.api.put(ENDPOINTS.companies, action.payload.id, action.payload).pipe(
        map(payload => ({ type: UPDATE_COMPANY_SUCCESS, payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  @Effect()
  deleteCompany: Observable<Action> = this.actions.pipe(
    ofType(DELETE_COMPANY),
    mergeMap((action: any) =>
      this.api.delete(ENDPOINTS.companies, action.payload).pipe(
        map(deletePayload => ({ type: DELETE_COMPANY_SUCCESS, payload: action.payload })),
        catchError(error => {
          this.errorHandlerService.apiError(error);
          return of({ type: RAISE_API_ERROR, payload: error.message });
        })
      )
    )
  );

  constructor(
    private api: ApiService,
    private actions: Actions,
    private errorHandlerService: ErrorHandlerService
  ) {}
}
